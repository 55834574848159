import React from 'react';
import { Steps, Typography } from 'antd';
import { TrophyOutlined, StarOutlined, CrownOutlined, RocketOutlined, FireOutlined, ThunderboltOutlined, SmileOutlined, InfoCircleTwoTone } from '@ant-design/icons';

const { Text, Title } = Typography;


const tierIcons = {
    'Explorer': <TrophyOutlined />,
    'Seeker': <StarOutlined />,
    'Candidate': <CrownOutlined />,
    'Contender': <RocketOutlined />,
    'Prime Candidate': <FireOutlined />,
    'Top Prospect': <ThunderboltOutlined />,
    'Leader': <SmileOutlined />
};

const TierRanking = ({ currentRanking }) => (
    <div>
        <Title level={3} style={{ textAlign: 'center' }}>
            Ranking
        </Title>
        <Steps
            current={currentRanking}
            items={[
                {
                    title: 'Explorer',
                    description: 'Tier 1',
                    icon: tierIcons['Explorer']
                },
                {
                    title: 'Seeker',
                    description: 'Tier 2',
                    icon: tierIcons['Seeker']
                },
                {
                    title: 'Candidate',
                    description: 'Tier 3',
                    icon: tierIcons['Candidate']
                },
                {
                    title: 'Contender',
                    description: 'Tier 4',
                    icon: tierIcons['Contender']
                },
                {
                    title: 'Prime Candidate',
                    description: 'Tier 5',
                    icon: tierIcons['Prime Candidate']
                },
                {
                    title: 'Top Prospect',
                    description: 'Tier 6',
                    icon: tierIcons['Top Prospect']
                },
                {
                    title: 'Leader',
                    description: 'Tier 7',
                    icon: tierIcons['Leader']
                }
            ]}
        />
    </div>
);

export default TierRanking;