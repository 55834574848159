import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Space, Alert, Progress, Typography, Collapse, Divider, Tooltip } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import EvalAnalysis from './Analysis';
import CardViewAnalysis from './CardViewAnalysis';
import TierRanking from './TierRanking';


const { Text, Title } = Typography;


const EvaluationReportModal = ({ visible, onClose, reportData, tier, dashboardscore, formatTier, lastUpdate, evaluateFunction, nextTierPrecentage, tierRanking }) => {

    //const results = reportData.results;

    const [requiredResults, setRequiredResults] = useState([]);
    const [missingResults, setMissingResults] = useState([]);
    const [errorResults, setErrorResults] = useState([]);
    const [sortResults, setSortResults] = useState([]);

    useEffect(() => {
        if (reportData && reportData.results) {

            const results = reportData.results;

            const required = results.filter(result => result.required === true && result.userBoost == 0 && !result.hide);
            const missing = results.filter(result => result.status === 'Missing' && !result.hide);
            const error = results.filter(result => result.status === 'Error' && !result.hide);
            const sort = results
                .filter(result => result && !result.hide)
                .sort((a, b) => (b.maxBoost - b.userBoost) - (a.maxBoost - a.userBoost));

            setRequiredResults(required);
            setMissingResults(missing);
            setErrorResults(error);
            setSortResults(sort);
        }
    }, [reportData]);

    const tierDescriptions = {
        'Explorer': 'Explorers are just starting their journey and are eager to learn and grow. They are in the early stages of their career and are building foundational skills. While they may not have extensive experience, their enthusiasm and willingness to learn make them promising candidates for entry-level positions.',
        'Seeker': 'Seekers are actively looking for opportunities to advance their skills and career. They have gained some experience and are focused on developing their expertise. Seekers are competitive for roles that require a basic understanding of the field and are eager to take on more responsibilities to prove their capabilities.',
        'Candidate': 'Candidates have demonstrated potential and are ready for new challenges. They have a solid track record of performance and have shown that they can handle more complex tasks. Candidates are competitive for mid-level positions where they can continue to grow and contribute significantly to their teams.',
        'Contender': 'Contenders are strong candidates who have shown significant promise. They have accumulated substantial experience and have a history of successful project completions. Contenders are highly competitive for senior roles and are often considered for leadership positions due to their proven abilities and potential for further growth.',
        'Rising Star': 'Rising Stars are standout individuals who are quickly making a name for themselves. They have consistently exceeded expectations and have been recognized for their exceptional performance. Rising Stars are highly competitive for top-tier positions and are often on the fast track to executive roles due to their impressive achievements and rapid career progression.',
        'Top Prospect': 'Top Prospects are highly sought after and have a proven track record of success. They have demonstrated excellence in their field and are often approached by top companies for their expertise. Top Prospects are extremely competitive for high-level positions and are considered valuable assets to any organization due to their extensive experience and outstanding performance.',
        'Prime Candidate': 'Prime Candidates are the best of the best, with exceptional skills and experience. They have reached the pinnacle of their careers and are recognized as leaders in their industry. Prime Candidates are the most competitive for executive and C-suite positions, where their strategic vision and leadership can drive significant organizational success.'
    };


    const ImpactSection = ({ title, description, results, color }) => {
        const [showMore, setShowMore] = useState(false);

        const handleViewMore = () => {
            setShowMore(!showMore);
        };

        return (
            <>
                {results.length > 0 && <Title style={{ fontSize: '1.5em', fontWeight: 'bold' }}>{title}</Title>}
                {results.length > 0 && (
                    <div style={{ marginBottom: '10px' }}>
                        <Text style={{ fontSize: '1.2em' }}>{description}</Text>
                    </div>
                )}
                {results?.map((result, index) => (
                    index < 3 ? (
                        <CardViewAnalysis result={result} color={color} key={index} />
                    ) : (
                        showMore && <EvalAnalysis result={result} key={index} />
                    )
                ))}
                {results.length > 3 && (
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
                        <Button onClick={handleViewMore}>
                            {showMore ? 'View Less' : 'View More'}
                        </Button>
                    </div>
                )}
                {results.length > 0 && <Divider />}
            </>
        );
    };

    // lastUpdate to a date
    const date = new Date(parseInt(lastUpdate, 10));

    let formattedDate = date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true
    });

    const reEvaluation = async () => {
        onClose();
        await evaluateFunction();
    }

    //for 2nd third
    const start = Math.ceil(sortResults.length / 3);
    const end = Math.ceil((sortResults.length * 2) / 3);

    //for 3rd third
    const Last = Math.ceil((sortResults.length * 2) / 3);





    return (
        <Modal
            centered
            title={
                <h1 style={{ fontSize: '1.3em', fontWeight: "bold", margin: '0px' }}>
                    Evaluation Report
                </h1>
            }
            visible={visible}
            onCancel={onClose}
            width={'90vw'}
            bodyStyle={{ height: '85vh', alignItems: 'center', justifyContent: 'center', overflowY: 'auto', marginBottom: '10%' }}
            footer={null}
            maskClosable={false}
        >
            <Space
                direction="vertical"
                style={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '10px'
                }}
            >
                <Progress
                    type="dashboard"
                    percent={dashboardscore}
                    strokeColor="#fcdc5c"
                    trailColor="rgba(0, 0, 0, 0.06)"
                    strokeWidth={12}
                    steps={{
                        count: 7,
                        gap: 4,
                    }}
                    gapDegree={125}
                    width={200}
                    format={formatTier}
                    style={{ marginBottom: '-40px' }}

                />
                <Text style={{ fontSize: '1.6em', fontWeight: 'bold' }}>Understanding Your Ranking</Text>
                <Text style={{ fontSize: '1.2em', textAlign: 'center' }}>{tierDescriptions[tier]}</Text>
                <TierRanking currentRanking={tierRanking} />
                <div style={{ flex: '1 1 100%', maxWidth: '100%', padding: '8px' }}>
                    <>
                        <Tooltip title="Your score is calculated based on the information you have provided in your profile.">
                            <InfoCircleTwoTone twoToneColor='#fcdc5c' style={{ fontSize: '14px' , marginRight: '5px' }} />
                        </Tooltip>
                        <Text>Progress to Next Tier</Text>
                        <Progress type='line' percent={nextTierPrecentage} status="active" strokeColor="#fcdc5c" style={{ width: '100%' }} />
                    </>
                </div>
                <Text style={{ fontSize: '0.8em' }}>Last Updated: {formattedDate}</Text>
                <Button size='small' onClick={reEvaluation} style={{ marginTop: '10px' }}>Re-evaluate</Button>

            </Space>


            {/* Critical */}
            {/* <ImpactSection
                title="Critical!"
                description="These factors are essential for your target job and often are required. Make sure to address these areas first."
                results={requiredResults}
                color="green"
            /> */}

            {/* Missing */}
            <ImpactSection
                title="Missing Data"
                description="We couldn't evaluate these factors because we don't have enough information. Please provide the missing data to get a more accurate evaluation."
                results={missingResults}
                color="orange"
            />

            <Title style={{ fontSize: '2em', fontWeight: 'bold' }}>Areas Of Improvement</Title>

            {/* High Impact */}
            <ImpactSection
                title="High Impact"
                description="These factors have the biggest effect on your candidacy score. That means it's really worth improving."
                results={sortResults.slice(0, Math.ceil(sortResults.length / 3))}
            // color="green"
            />

            {/* Medium Impact */}
            <ImpactSection
                title="Medium Impact"
                description="These factors have a moderate effect on your candidacy score. Consider improving these areas."
                results={sortResults.slice(start, end)}
            // color="yellow"
            />

            {/* Low Impact */}
            <ImpactSection
                title="Low Impact"
                description="These factors have a minor effect on your candidacy score. They are less critical but still worth addressing."
                results={sortResults.slice(Last)}
            // color="red"
            />

            {/* Report */}
            {/* <div style={{ marginBottom: '5px' }}>
                {sortResults.length > 0 ? <Text style={{ fontSize: '1.2em', fontWeight: 'bold' }}>All Results</Text> : null}
            </div>
            {sortResults?.map((result, index) => (
                <EvalAnalysis result={result} key={index} />
            ))} */}

        </Modal>
    );

}

export default EvaluationReportModal;