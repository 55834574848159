import React, { useEffect, useState, useRef, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { QUERY_MY_CONTACTS } from '../../utils/queries.js';
import { Table, Dropdown, Menu, Button, Checkbox, DatePicker, TimePicker, Drawer, Space, Divider, Badge, InputNumber } from 'antd'; // Import the necessary components
import { DownOutlined, StarOutlined, StarFilled, MailOutlined } from '@ant-design/icons'; // Import the specific icons
import EditContactModal from '../../components/Modals/EditContactModal/index.js';
import { useMutation } from "@apollo/client";
import { EDIT_CONTACT_RELATION } from "../../utils/mutations.js";
import { EDIT_CONTACT_REPLIED } from "../../utils/mutations.js";
import { FAVOURITE_CONTACT } from "../../utils/mutations.js";
import { UPDATE_MEETING_DATE } from "../../utils/mutations.js";
import ContactMessageModal from '../../components/Modals/ContactMessageModal/index.js';
import moment from 'moment';
import dayjs from 'dayjs';
import MeetingCalendar from '../../components/Calendar/index.js';
import LastMessageModal from '../../components/Modals/LastMessageModal/index.js';
import { useTour } from '../../contexts/tourContext.js';

import MailBoxModal from '../../components/Modals/MailBoxModal/index.js';
import { UserContext } from '../../contexts/userContext.js';

import { useLocation } from 'react-router-dom';

const { RangePicker } = TimePicker;
const { Column } = Table;

const ContactsList = ({ onAddclosed, openFilters, closeFilters }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { data, loading, refetch } = useQuery(QUERY_MY_CONTACTS);
    const [isLoading, setIsLoading] = useState(true);
    const [contacts, setContacts] = useState([]);
    const [currentContact, setCurrentContact] = useState(null);
    const [isContactModalOpen, setisContactModalOpen] = useState(false);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [editContact, { error }] = useMutation(EDIT_CONTACT_RELATION);
    const [editContactReplied, { error2 }] = useMutation(EDIT_CONTACT_REPLIED);
    const [favouriteContact] = useMutation(FAVOURITE_CONTACT);
    const [queriedContactID, setQueriedContactID] = useState(queryParams.get('id') || null);
    const [fav, setFav] = useState(queryParams.get('fav') || false);
    const [contacted, setContacted] = useState(queryParams.get('contacted') || 'all');
    const [replied, setReplied] = useState(queryParams.get('replied') || 'all');
    const [followedUp, setFollowedUp] = useState(queryParams.get('followedUp') || 'all');
    const [contactDateOlderThan, setContactDateOlderThan] = useState(queryParams.get('contactDateOlderThan') || null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [updateMeetingDate] = useMutation(UPDATE_MEETING_DATE);
    const [showCalendar, setShowCalendar] = useState(false);
    const [modalMessageType, setModalMessageType] = useState("network");
    const [isMailBoxModalOpen, setisMailBoxModalOpen] = useState(false);
    const { allMyEmails } = useContext(UserContext);
    const [selectedEmails, setSelectedEmails] = useState([]);

    // const { setTourSteps, startTour } = useTour();
    // const ref1 = useRef(null);
    // const ref2 = useRef(null);
    // const ref3 = useRef(null);
    // const ref4 = useRef(null);
    // const ref5 = useRef(null);
    // const ref6 = useRef(null);
    // const ref7 = useRef(null);

    // useEffect(() => {
    //     setTourSteps([
    //         {
    //             target: () => ref1.current,
    //             placement: 'right',
    //             title: 'Favourite',
    //             content: 'Favourite your contacts to keep track of your most important connections.',
    //         },
    //         {
    //             target: () => ref2.current,
    //             placement: 'right',
    //             title: 'Relationship',
    //             content: 'Categorize your contacts by relationship type.',
    //         },
    //         {
    //             target: () => ref3.current,
    //             placement: 'right',
    //             title: 'Contact',
    //             content: 'Open up our message modal to craft a message to your contact.',
    //         },
    //         {
    //             target: () => ref4.current,
    //             placement: 'left',
    //             title: 'Related Job Posting',
    //             content: 'Job Posting related to the contact will be displayed here.',
    //         },
    //         {
    //             target: () => ref5.current,
    //             placement: 'left',
    //             title: 'Last Contacted',
    //             content: 'View the last time you contacted your contact and/or create a follow-up message.',
    //         },
    //         {
    //             target: () => ref6.current,
    //             placement: 'left',
    //             title: 'Replied',
    //             content: 'Mark if your contact has replied to your message.',
    //         },
    //         {
    //             target: () => ref7.current,
    //             placement: 'left',
    //             title: 'Edit',
    //             content: 'Edit your contact details.',
    //         }
    //     ]);

    //     var isFirstTimeContacts = localStorage.getItem('isFirstTimeContacts');
    //     var isFirstTimeContactsList = localStorage.getItem('isFirstTimeContactsList');

    //     startTour()

    //     if (isFirstTimeContacts) {
    //         if (!isFirstTimeContactsList) {
    //             startTour();
    //             localStorage.setItem('isFirstTimeContactsList', 'true');
    //         }
    //     }

    // }, [setTourSteps]);


    const openContactModal = () => {
        setisContactModalOpen(true);
    };

    const closeContactModal = () => {
        setisContactModalOpen(false);
        refetch();
    };

    const openMessageModal = () => {
        setIsMessageModalOpen(true);
    };

    const closeMessageModal = () => {
        setIsMessageModalOpen(false);
        refetch();
    };

    if (!onAddclosed) {
        refetch();
    }

    useEffect(() => {
        if (data) {
            setIsLoading(false);
            setContacts(data.myContacts);
        }
    }, [data]);

    useEffect(() => {
        refetch();
    }, [refetch]);

    //console.log(contacts);

    const handleEdit = (contact) => {
        openContactModal();
        setCurrentContact(contact);
    };

    const handleRelationChange = async (e, contact) => {
        console.log("handleRelationChange called");
        console.log(e.key);
        console.log(contact._id);
        try {
            // Call the mutation to update the contact's relation in the database
            const { data } = await editContact({
                variables: {
                    id: contact._id,
                    relationship: e.key
                }
            });
            console.log(data);
        } catch (err) {
            console.error("Error updating relationship:", err);
        }
    };

    const relationMenu = (contact) => (
        <Menu onClick={(e) => handleRelationChange(e, contact)}>
            <Menu.Item key="Family">Family</Menu.Item>
            <Menu.Item key="Friend">Friend</Menu.Item>
            <Menu.Item key="Mutual">Mutual</Menu.Item>
            <Menu.Item key="Co-Worker">Co-Worker</Menu.Item>
            <Menu.Item key="Alumni">Alumni</Menu.Item>
            <Menu.Item key="Recruiter">Recruiter</Menu.Item>
            <Menu.Item key="Hiring Manager">Hiring Manager</Menu.Item>
            <Menu.Item key="Other">Other</Menu.Item>
            {/* Add more relations as needed */}
        </Menu>
    );

    const repliedChange = async (e, id) => {
        console.log(e.target.checked);
        console.log(id);
        //Edit the contact's replied field in the database
        try {
            // Call the mutation to update the contact's replied field in the database
            const { data } = await editContactReplied({
                variables: {
                    id: id,
                    replied: e.target.checked,
                    repliedDate: new Date().toLocaleDateString()
                }
            });
            console.log(data);
        } catch (err) {
            console.error("Error updating replied:", err);
        }
    }

    const favContact = async (favStatus, id) => {
        try {
            const { data } = await favouriteContact({
                variables: {
                    id: id,
                    favourite: favStatus,
                }
            });
        } catch (err) {
            console.error("Error updating replied:", err);
        }
    }

    const meetingDateChange = async (date, times, id) => {
        console.log(date);
        console.log("times: " + times);
        console.log(id);
        //Edit the contact's replied field in the database
        try {
            // Call the mutation to update the contact's replied field in the database
            const { data } = await updateMeetingDate({
                variables: {
                    id: id,
                    meetingDate: date ? date : null,
                    meetingStart: times[0] ? times[0].format("HH:mm") : null,
                    meetingEnd: times[1] ? times[1].format("HH:mm") : null,
                }
            });
            console.log(data);
        } catch (err) {
            console.error("Error updating replied:", err);
        }
    }

    const handleFavClick = () => {
        setFav(!fav);
    };

    const handleContactedClick = (value) => {
        if (value === contacted) {
            setContacted('all');
            return;
        }
        setContacted(value);
    };

    const handleRepliedClick = (value) => {
        if (value === replied) {
            setReplied('all');
            return;
        }
        setReplied(value);
    }

    const handleFollowedUpClick = (value) => {
        if (value === followedUp) {
            setFollowedUp('all');
            return;
        }
        setFollowedUp(value);
    }

    const handleContactDateOlderThanClick = (value) => {
        setContactDateOlderThan(value);
    }

    const handleSortClick = (value) => {
        setSortOrder(value);
    }

    const handleCalendarClick = () => {
        setShowCalendar(!showCalendar);
    }

    const sortContacts = (contacts, order) => {
        return contacts.slice().sort((a, b) => {
            // if (order === 'asc') {
            //     return a.firstName.localeCompare(b.firstName);
            // } else {
            //     return b.firstName.localeCompare(a.firstName);
            // }
            const dateA = a.lastContacted;
            const dateB = b.lastContacted;

            if (order === 'asc') {
                return dateA - dateB;
            } else {
                return dateB - dateA;
            }
        });
    };

    const applyFilters = (contacts) => {
        const filterFunctions = [];

        //filterbyId
        if (queriedContactID) {
            filterFunctions.push((contact) => contact._id === queriedContactID);
        }

        if (fav) {
            filterFunctions.push((contact) => contact.favourite === true);
        }

        if (contacted === 'yes') {
            filterFunctions.push((contact) => contact.lastContacted !== null);
        } else if (contacted === 'no') {
            filterFunctions.push((contact) => contact.lastContacted === null);
        }

        if (replied === 'yes') {
            filterFunctions.push((contact) => contact.replied === true);
        } else if (replied === 'no') {
            filterFunctions.push((contact) => contact.replied === false || contact.replied === null);
        }

        if (followedUp === 'yes') {
            filterFunctions.push((contact) => contact.lastfollowUp !== null);
        }
        else if (followedUp === 'no') {
            filterFunctions.push((contact) => contact.lastfollowUp === null);
        }

        if (contactDateOlderThan) {
            filterFunctions.push((contact) => {
                if (!contact.lastContacted) return false;
                const today = new Date();
                const lastContactedDate = contact.lastContacted
                const differenceInDays = (today - lastContactedDate) / (1000 * 60 * 60 * 24);
                return differenceInDays > contactDateOlderThan;
            });
        }


        return contacts.filter((contact) => filterFunctions.every((filterFn) => filterFn(contact)));
    };

    const filteredAndSortedContacts = sortContacts(
        applyFilters(contacts),
        sortOrder
    );

    return (

        <div className="contacts-list-container" style={{ display: 'flex', flexDirection: 'column' }}>
            <Drawer
                title="Filters & Sort"
                placement={"right"}
                onClose={closeFilters}
                open={openFilters}
            >

                <Space direction="vertical" size="middle">
                    <Space>
                        <span>Favorites Only:</span>
                        <Button
                            type="default"
                            size="small"
                            onClick={() => handleFavClick()}
                            className={fav === true ? 'active' : ''}
                        >
                            Favorites
                        </Button>
                    </Space>
                    <Divider />
                    <Space>
                        <span>Sort:</span>
                        <Button
                            type="default"
                            size="small"
                            onClick={() => handleSortClick('asc')}
                        >
                            Ascending
                        </Button>
                        <Button
                            type="default"
                            size="small"
                            onClick={() => handleSortClick('desc')}
                        >
                            Descending
                        </Button>
                    </Space>
                    {/* <Divider />
                    <Space>
                        <span>Filter By Dates Greater Than</span>
                        <InputNumber
                            min={0}
                            placeholder="Enter number of days"
                            onChange={(value) => setContactDateOlderThan(value)}
                        />
                    </Space> */}
                    <Divider />
                    <Space>
                        <span>Contacted:</span>
                        <Button
                            type="default"
                            size="small"
                            onClick={() => handleContactedClick("yes")}
                            className={contacted === 'yes' ? 'active' : ''}
                        >
                            Yes
                        </Button>
                        <Button
                            type="default"
                            size="small"
                            onClick={() => handleContactedClick("no")}
                            className={contacted === 'no' ? 'active' : ''}
                        >
                            No
                        </Button>
                    </Space>
                    <Divider />
                    <Space>
                        <span>Replied:</span>
                        <Button
                            type="default"
                            size="small"
                            onClick={() => handleRepliedClick("yes")}
                            className={replied === 'yes' ? 'active' : ''}
                        >
                            Yes
                        </Button>
                        <Button
                            type="default"
                            size="small"
                            onClick={() => handleRepliedClick("no")}
                            className={replied === 'no' ? 'active' : ''}
                        >
                            No
                        </Button>
                    </Space>
                    <Divider />
                    <Space>
                        <span>Followed Up:</span>
                        <Button
                            type="default"
                            size="small"
                            onClick={() => handleFollowedUpClick("yes")}
                            className={followedUp === 'yes' ? 'active' : ''}
                        >
                            Yes
                        </Button>
                        <Button
                            type="default"
                            size="small"
                            onClick={() => handleFollowedUpClick("no")}
                            className={followedUp === 'no' ? 'active' : ''}
                        >
                            No
                        </Button>
                    </Space>
                    <Divider />
                    <Divider type="vertical" />
                    {/* <Button
                    type="default"
                    size="small"
                    onClick={() => handleCalendarClick()}
                >
                    View Calendar
                </Button> */}
                </Space>

            </Drawer>
            {/* <div>
                {showCalendar && <MeetingCalendar Data={contacts} />}
            </div> */}
            <Table dataSource={filteredAndSortedContacts} loading={isLoading} rowKey={record => record._id}>
                <Column
                    responsive={['lg']}
                    title="Favourite"
                    dataIndex="favourite"
                    key="favourite"
                    render={(_, record) => (
                        <>
                            <div className="favorite-icon" onClick={(e) => favContact(!record.favourite, record._id)}>
                                {record.favourite ? <StarFilled style={{ color: "#fcdc5c" }} /> : <StarOutlined style={{ color: "#5ce4f4" }} />}
                            </div>
                        </>
                    )}
                />
                <Column
                    title="Name & Company"
                    key="nameCompany"
                    render={(_, record) => (
                        <>
                            <div style={{ fontWeight: 'bold', fontSize: '18px' }}>
                                {record.contactDetails.firstName} {record.contactDetails.lastName}
                            </div>
                            <div>
                                {record.contactDetails.jobTitle}{record.contactDetails.jobTitle && record.contactDetails.company ? ", " : ""} {record.contactDetails.company ? record.contactDetails.company.companyName : ""}
                            </div>
                        </>
                    )}
                />
                <Column
                    responsive={['lg']}
                    title="Relation"
                    key="relation"
                    render={(_, record) => (
                        <Dropdown overlay={relationMenu(record)}>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                {record.relationship} <DownOutlined />
                            </a>
                        </Dropdown>
                    )}
                />
                <Column
                    title="Contact"
                    key="contact"
                    render={(_, record) => (
                        <Button className="button-color"
                            onClick={() => {
                                setSelectedEmails(allMyEmails.filter(email => email.userContactId && email.userContactId._id == record._id))
                                openMessageModal();
                                setCurrentContact(record);
                                setModalMessageType("network")
                            }}>Contact</Button>
                    )}
                />
                <Column
                    responsive={['lg']}
                    title="Related Job Posting"
                    key="jobDetails"
                    render={(_, record) => (
                        <>
                            {record.relatedJobPosting ? (
                                <>
                                    {record.relatedJobPosting.company}
                                    <br />
                                    {record.relatedJobPosting.position}
                                </>
                            ) : "N/A"}
                        </>
                    )}
                />
                <Column
                    title="Last Contacted"
                    // dataIndex="lastContacted"
                    key="lastContacted"
                    render={(_, record) => {
                        if (record.lastContacted || record.lastfollowUp) {
                            const latestDate = record.lastfollowUp ? record.lastfollowUp : record.lastContacted;
                            const date = new Date(parseInt(latestDate, 10));
                            const formattedDate = date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
                            return (
                                <>
                                    {/* {new Date(parseInt(lastContacted, 10)).toLocaleDateString()} */}
                                    <Badge
                                        count={allMyEmails.filter(email => email.userContactId && email.userContactId._id == record._id && email.read === false).length}
                                        style={{
                                            backgroundColor: 'red',
                                        }}
                                        offset={[0, 30]}
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>


                                            <LastMessageModal
                                                date={formattedDate}
                                                firstMessage={record.lastContactMessage}
                                                followUpMessage={record.lastfollowUpMessage}

                                            />
                                            {/* {allMyEmails.filter(email => email.userContactId && email.userContactId._id == record._id).length < 1 ? ( */}
                                            <>
                                                <Button
                                                    style={{
                                                        marginTop: '4px',
                                                        width: '100%',
                                                        backgroundColor: record.lastfollowUpMessage ? '#fcdc5c' : undefined
                                                    }}
                                                    size='small'
                                                    onClick={() => {
                                                        setSelectedEmails(allMyEmails.filter(email => email.userContactId && email.userContactId._id == record._id))
                                                        openMessageModal();
                                                        setCurrentContact(record);
                                                        setModalMessageType("followUp")
                                                    }}
                                                >
                                                    Follow-Up
                                                </Button>
                                            </>
                                            {/* ) : ( */}
                                            <>
                                                {/* {allMyEmails.filter(email => email.userContactId && email.userContactId._id == record._id).length > 0 && (

                                                        <Button
                                                            style={{
                                                                marginTop: '4px',
                                                                width: '100%',
                                                                backgroundColor: '#2ca4cc',
                                                                color: 'white'
                                                            }}
                                                            size='small'
                                                            icon={<MailOutlined />}
                                                            onClick={() => {
                                                                setCurrentContact(record)
                                                                setSelectedEmails(allMyEmails.filter(email => email.userContactId && email.userContactId._id == record._id));
                                                                setisMailBoxModalOpen(true);
                                                            }}
                                                        >

                                                            Mail Box
                                                        </Button>


                                                    )} */}
                                            </>
                                            {/* )} */}
                                        </div>
                                    </Badge>
                                </>
                            );
                        } else {
                            return "N/A";
                        }
                    }}
                />
                <Column
                    responsive={['lg']}
                    title="Replied"
                    dataIndex="replied"
                    key="replied"
                    render={(_, record) => <Checkbox defaultChecked={record.replied} onChange={(e) => repliedChange(e, record._id)} />}
                />
                {/* <Column
                    title="Meeting Date"
                    dataIndex="meetingDate"
                    key="meetingDate"
                    render={(meetingDate, record) => (
                        console.log(moment(meetingDate, moment.ISO_8601)),
                        <DatePicker
                            // value={meetingDate ? moment(meetingDate) : null}
                            onChange={(date) => meetingDateChange(date, record._id)}
                        />
                        
                    )}
                /> */}
                {/* <Column
                    title="Meeting Date"
                    dataIndex={null}
                    key="meeting"
                    render={(text, record) => {
                        const date = record.meeting.date ? dayjs(parseInt(record.meeting.date)) : null;
                        const timeRange = record.meeting.start && record.meeting.end ? [moment(record.meeting.start, 'HH:mm'), moment(record.meeting.end, 'HH:mm')] : [];
                        return (
                            <>
                                <DatePicker
                                    value={date}
                                    onChange={(date) => meetingDateChange(date, timeRange, record._id)}
                                />
                                <RangePicker
                                    value={timeRange}
                                    format="HH:mm"
                                    minuteStep={5}
                                    onOk={(times) => meetingDateChange(date, times, record._id)}
                                />
                            </>
                        );
                    }}
                /> */}
                <Column
                    responsive={['lg']}
                    title=""
                    key="edit"
                    render={(_, record) => (
                        <Button className="button-color" type="primary" onClick={() => handleEdit(record)}>
                            Edit
                        </Button>
                    )}
                />
            </Table>
            {isContactModalOpen && (
                <EditContactModal onClose={closeContactModal} contact={currentContact} />
            )}
            {isMessageModalOpen && (
                <ContactMessageModal onClose={closeMessageModal} record={currentContact} contact={"person"} messageType={modalMessageType} myEmails={selectedEmails} />
            )}
            {isMailBoxModalOpen && (
                <MailBoxModal visible={isMailBoxModalOpen} onClose={() => setisMailBoxModalOpen(false)} contact={currentContact} myEmails={selectedEmails} />
            )}
        </div>
    );
}

export default ContactsList;
