import React, { useEffect, useState, useContext, useRef } from 'react';
import CompanyMatchCard from "../../components/Cards/CompanyMatchCard/index.js";
import { useQuery } from '@apollo/client';
import { QUERY_PUBLIC_COMPANIES } from '../../utils/queries.js'
import { QUERY_ONLY_MY_COMPANIES_IDS } from '../../utils/queries.js'
import { UserContext } from '../../contexts/userContext.js';
import { backgroundJobCheck } from '../../utils/helpers/background.js';
import { startGenerateMatchesTask, getGenerateMatchesTaskStatus, getGenerateMatchesTaskResult } from '../../utils/FindMatchAPIs/generateMatches.js';
import LoadingMatches from '../../components/Loading/LoadingMatches/index';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Button, Spin } from 'antd';
import './Matcher.css';
import useIsMobile from '../../contexts/useIsMobile'
import RecommendationsModal from '../../components/Modals/RecommendationsModal/index.js';
import { QUERY_MY_EVALUATION_REPORT } from '../../utils/queries.js';
import { QUERY_ALL_PUBLIC_JOB_POSTINGS } from '../../utils/queries.js';
import { QUERY_ONLY_MY_APPLICATIONS_IDS } from '../../utils/queries.js';
import JobPostingsMatchCard from '../../components/Cards/JobPostingsMatchCard/index.js';
import JobPostingPopUpModal from '../../components/Modals/MatcherPopUpModal/JobPostings/index.js';
import CompanyPopUpModal from '../../components/Modals/MatcherPopUpModal/Companies/index.js';

const Matcher = () => {


    const { user } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [companies, setCompanies] = useState([]);
    const { data: companyData, refetch, loading: companiesLoading } = useQuery(QUERY_PUBLIC_COMPANIES);
    const { data: myCompanyData, refetch: refetchMyCompanies, loading: myCompaniesLoading } = useQuery(QUERY_ONLY_MY_COMPANIES_IDS);
    const [currentCompanyIndex, setCurrentCompanyIndex] = useState(0);
    const [showMatches, setShowMatches] = useState(false);
    const [allCompaniesReviewed, setAllCompaniesReviewed] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const isMobile = useIsMobile();
    const [showRecommendationsModal, setShowRecommendationsModal] = useState(false);
    const [evalReportFound, setEvalReportFound] = useState(true);
    const [showMatchProgress, setShowMatchProgress] = useState(false);
    const [searchFinished, setSearchFinished] = useState(false);
    const [progress, setProgress] = useState(0);
    const { data: publicJobPostingsData, refetch: refetchPublicJobPostings, loading: loadingJobPostings } = useQuery(QUERY_ALL_PUBLIC_JOB_POSTINGS);
    const { data: myApplicationsData, refetch: refetchMyApplications, loading: loadingApplications } = useQuery(QUERY_ONLY_MY_APPLICATIONS_IDS);
    const [jobPostings, setJobPostings] = useState([]);
    const [allMatches, setAllMatches] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [jobPostingModalVisible, setJobPostingModalVisible] = useState(false);
    const [companiesModalVisible, setCompaniesModalVisible] = useState(false);
    const [jobPostingModalShown, setJobPostingModalShown] = useState(false);
    const [companiesModalShown, setCompaniesModalShown] = useState(false);

    const [totalLikes, setTotalLikes] = useState(user ? user.matches.matchesLiked : 0);

    const { data: evaluationReportData } = useQuery(QUERY_MY_EVALUATION_REPORT, {
        skip: !user, // Skip the query if user is not available
        variables: { userId: user ? user._id : '' }
    });

    const hasPerformedMatch = useRef(false);

    const maxlikes = 50
    const maxMatchesFound = 50

    const findMatches = async () => {
        // Clear current matches
        setCompanies([]);

        setSearchFinished(false);
        setShowMatchProgress(true);
        setProgress(0);
        setCurrentCompanyIndex(0);

        console.log('Finding Matches...');

        // Start generateMatches task and get a task ID
        let taskResponse = await startGenerateMatchesTask(user._id);
        let taskId = taskResponse.data.taskID;

        // Check the status of the task and get the result
        let matchResultsPromise = backgroundJobCheck(getGenerateMatchesTaskStatus, getGenerateMatchesTaskResult, taskId);

        // Create a timeout promise that resolves after 6 seconds
        const timeoutPromise = new Promise(resolve => setTimeout(resolve, 2000));

        // Wait for both the match results and the timeout
        let [matchResults] = await Promise.all([matchResultsPromise, timeoutPromise]);

        // Refetch Companies
        await refetchMyCompanies();
        await refetch();
        // Refetch Job Postings
        await refetchPublicJobPostings();
        await refetchMyApplications();

        setSearchFinished(true);

        return matchResults;
    };


    // useEffect to handle the filtering and setting of companies
    useEffect(() => {
        const updateCompanies = async () => {
            if (user && user.matches) {
                //Set Total Likes
                setTotalLikes(user.matches.matchesLiked);
                //----Check if user has completed the matcher signature
                if (user.matcherSignature) {
                    const nonEmptyFields = Object.values(user.matcherSignature).filter(value => value !== null && value !== undefined && value !== '');
                    if (nonEmptyFields.length < 4) {
                        setShowRecommendationsModal(true);
                    }
                }


                //----Check if user has an evaluation report
                if (!evaluationReportData || !evaluationReportData.getEvaluationReportByUserID) {
                    console.log('No Evaluation Report');
                    setEvalReportFound(false);
                    setShowRecommendationsModal(true);

                }


                if (isFirstLoad) {
                    // Trigger refetches to ensure data is up-to-date
                    if ((user.matches.matchesFound.thisWeek >= maxMatchesFound || totalLikes >= maxlikes) && !hasPerformedMatch.current) {
                        hasPerformedMatch.current = true;
                        const matchResults = await findMatches();

                        if (matchResults.result && !matchResults.result.includes("MAX")) {
                            setTotalLikes(0);
                        } else {
                            setAllCompaniesReviewed(true);
                        }
                    }

                    await Promise.all([
                        refetch(),
                        refetchMyCompanies(),
                        refetchPublicJobPostings(),
                        refetchMyApplications()
                    ]);

                    setIsFirstLoad(false); // Set to false so it doesn't refetch on subsequent renders
                }

                // Initialize loading states
                let jobPostingsLoading = true;
                let companiesLoading = true;
                let filteredJobPostings = [];
                let filteredCompanies = [];

                //-----Get Job Posting Matches
                if (publicJobPostingsData && publicJobPostingsData.publicJobPostings && user && user._id && myApplicationsData && myApplicationsData.myApplications) {
                    filteredJobPostings = await publicJobPostingsData.publicJobPostings.filter(jobPosting =>
                        // jobPosting.recommendedTo && jobPosting.recommendedTo.some(recUser => recUser && recUser._id === user._id) &&
                        !myApplicationsData.myApplications.some(myApplication => myApplication.job && myApplication.job._id === jobPosting._id)
                    );
                    setJobPostings(filteredJobPostings);
                    jobPostingsLoading = false;
                }


                //-----Get Company Matches
                if (companyData && companyData.publicCompanies && user && user._id && myCompanyData && myCompanyData.myCompanies) {
                    filteredCompanies = await companyData.publicCompanies.filter(company =>
                        // company.recommendedTo && company.recommendedTo.some(recUser => recUser && recUser._id === user._id) &&
                        !myCompanyData.myCompanies.some(myCompany => myCompany.companyDetails && myCompany.companyDetails._id === company._id)
                    );
                    setCompanies(filteredCompanies);
                    companiesLoading = false;
                }

                // Combine Job Postings and Companies
                if (!jobPostingsLoading && !companiesLoading) { 
                    if (filteredJobPostings.length > 0 || filteredCompanies.length > 0) {
                        const combinedMatches = [...filteredJobPostings, ...filteredCompanies];
                        setAllMatches(combinedMatches);
                    }
                    setLoading(false);
                    setIsDataLoaded(true);
                }
            }
        };

        updateCompanies();


    }, [user, isFirstLoad, companyData, myCompanyData, publicJobPostingsData, myApplicationsData]);


    useEffect(() => {
        let interval;
        if (searchFinished) {
            interval = setInterval(() => {
                setProgress((prevProgress) => {
                    if (prevProgress >= 100) {
                        clearInterval(interval);
                        setTimeout(() => setShowMatchProgress(false), 2000); // Stay at 100% for 2 seconds
                        return 100;
                    }
                    return prevProgress + 10; // Speed up the progress
                });
            }, 50); // Faster interval time to quickly reach 100%
        } else if (showMatchProgress) {
            interval = setInterval(() => {
                setProgress((prevProgress) => {
                    if (prevProgress >= 100) {
                        clearInterval(interval);
                        return 100;
                    }
                    return prevProgress + 1;
                });
            }, 500); // Adjust the interval time as needed
        }

        return () => clearInterval(interval);
    }, [searchFinished, showMatchProgress]);

    useEffect(() => {
        if (!jobPostingModalShown && user?.matches?.matchesFound?.total <= 5 && allMatches[currentCompanyIndex]?.__typename === 'JobPosting') {
            setJobPostingModalVisible(true);
            setJobPostingModalShown(true);
        }
        if (!companiesModalShown && user?.matches?.matchesFound?.total <= 5 && allMatches[currentCompanyIndex]?.__typename === 'Company') {
            setCompaniesModalVisible(true);
            setCompaniesModalShown(true);
        }
    }, [currentCompanyIndex, jobPostingModalShown, companiesModalShown, user?.matches?.matchesFound?.total, allMatches, setJobPostingModalVisible, setCompaniesModalVisible]);
    


    const next = (notLiked) => {

        if (!notLiked) {
            var currentLikes = totalLikes + 1
            setTotalLikes(currentLikes)
            if (currentLikes >= maxlikes) {
                setAllCompaniesReviewed(true);
                return
            }
        }

        const nextIndex = (currentCompanyIndex + 1) % allMatches.length;
        if (nextIndex === 0) {
            findMatches();
        } else {
            setCurrentCompanyIndex(nextIndex);
        }
    }

    const prev = () => {
        setCurrentCompanyIndex((currentCompanyIndex - 1 + allMatches.length) % allMatches.length);
    }


    //If we are loading matches
    if (showMatchProgress) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <LoadingMatches progress={progress} />
            </div>
        );
    }

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Spin size="large" tip="Loading..." />
            </div>
        );
    }


    // If there are no companies or all companies have been reviewed, show a message
    if (isDataLoaded && (!allMatches.length || allCompaniesReviewed)) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f8f9fa', color: '#343a40' }}>

                {(user.matches.matchesFound.thisWeek >= maxMatchesFound || totalLikes >= maxlikes || user?.matches?.paidAPIRequests >= 15) ? (
                    <>
                        <p style={{ textAlign: 'center', margin: '20px', fontSize: '1.2em', fontWeight: 'normal' }}>You've gone through all your matches for now!</p>
                        <p style={{ textAlign: 'center', margin: '20px', fontSize: '1.8em', fontWeight: 'normal' }}>Head <a href="/" style={{ color: '#007bff' }}>Home</a> for your the next steps in your job hunt journey.</p>
                    </>
                ) : (
                    <>
                        <p style={{ textAlign: 'center', margin: '20px', fontSize: '1.2em', fontWeight: 'normal' }}>No matches found at this time.</p>
                        <Button onClick={findMatches}>
                            Find Matches
                        </Button>
                    </>
                )}

                {isMobile ? (
                    <>
                        <p style={{ textAlign: 'center', margin: '20px', fontSize: '1.2em', fontWeight: 'normal' }}>Or</p>
                        <p style={{ textAlign: 'center', margin: '20px', fontSize: '1.2em', fontWeight: 'normal' }}>
                            Check your <a href="/inbox" style={{ color: '#007bff' }}>Inbox</a> to view your replies. Please visit us on your desktop to access more features designed to help you land your dream job.
                        </p>
                    </>
                ) : (
                    <>
                        <p style={{ textAlign: 'center', margin: '20px', fontSize: '1.2em', fontWeight: 'normal' }}>Or</p>
                        <p style={{ textAlign: 'center', margin: '20px', fontSize: '1.2em', fontWeight: 'normal' }}>
                            Review your existing <a href="/contacts" style={{ color: '#007bff' }}>contacts</a> or check on your <a href="/applications" style={{ color: '#007bff' }}>applications</a>.
                        </p>
                    </>
                )}
            </div>
        );
    }

    // If there are companies to show, display them
    return (
        !showMatches ?
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <p className='discover-text'>
                    Exciting news! We've discovered {allMatches.length} fantastic opportunities just for you. Ready to explore?
                </p>
                <Button className='button-color-premium' size="large" onClick={() => setShowMatches(true)}>Discover Matches</Button>
            </div>
            :
            <div className="main-container" >
                <div className="title-section">
                    <h1 className="title">Matches</h1>
                    <h1 className='count'>{`${currentCompanyIndex + 1}/${allMatches.length}`}</h1>
                </div>
                <div style={{ position: 'relative', width: '100%' }}>
                    <TransitionGroup>
                        <CSSTransition
                            key={currentCompanyIndex}
                            timeout={1500}
                            classNames="fade"
                        >
                            {allMatches[currentCompanyIndex].__typename == 'Company' ? (
                                <>
                                <CompanyMatchCard match={allMatches[currentCompanyIndex]} next={next} />
                                </>
                            ) : (
                                <>
                                <JobPostingsMatchCard match={allMatches[currentCompanyIndex]} next={next} />
                                </>
                            )}
                            {/* <JobPostingsMatchCard match={allMatches[currentCompanyIndex]} next={next} /> */}
                            {/* <CompanyMatchCard match={allMatches[currentCompanyIndex]} next={next} /> */}
                        </CSSTransition>
                    </TransitionGroup>
                </div>
                {showRecommendationsModal && <RecommendationsModal reportFound={evalReportFound} visible={showRecommendationsModal} onClose={() => setShowRecommendationsModal(false)} />}
                {jobPostingModalVisible && <JobPostingPopUpModal visible={jobPostingModalVisible} onClose={() => setJobPostingModalVisible(false)} />}
                {companiesModalVisible && <CompanyPopUpModal visible={companiesModalVisible} onClose={() => setCompaniesModalVisible(false)} />}
            </div>
    )
}

export default Matcher;