import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Button, Typography, Card, Space, Divider, Progress, Badge, Spin, Image, Tooltip, Steps, notification, Descriptions } from 'antd';
import { UserContext } from '../../../contexts/userContext';
import { useQuery } from '@apollo/client';
import { QUERY_MY_EVALUATION_REPORT } from '../../../utils/queries';
import useIsMobile from '../../../contexts/useIsMobile';
import { TrophyOutlined, StarOutlined, CrownOutlined, RocketOutlined, FireOutlined, ThunderboltOutlined, SmileOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { startEvaluateTask, getEvaluateTaskStatus, getEvaluateTaskResult } from '../../../utils/EvaluateAPIs/evaluate';
import { backgroundJobCheck } from '../../../utils/helpers/background.js';
import EvaluationReportModal from '../../Modals/EvaluationReportModal';
import { useNavigate } from 'react-router-dom';
const { Title, Text } = Typography;


const Target = () => {

    const isMobile = useIsMobile();
    const { user } = useContext(UserContext);
    const [evaluationReport, setEvaluationReport] = useState({});
    const [evaluationLoading, setEvaluationLoading] = useState(false);
    const [evaluationReportModalVisible, setEvaluationReportModalVisible] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();

    const { data: evaluationReportData, refetch, loading } = useQuery(QUERY_MY_EVALUATION_REPORT, {
        skip: !user, // Skip the query if user is not available
        variables: { userId: user ? user._id : '' }
    });

    useEffect(() => {
        //console.log('evaluationReportData', evaluationReportData);
        if (evaluationReportData) {
            setEvaluationReport(evaluationReportData.getEvaluationReportByUserID);
        }
    }, [evaluationReportData]);

    const tierIcons = {
        'Explorer': <TrophyOutlined />,
        'Seeker': <StarOutlined />,
        'Candidate': <CrownOutlined />,
        'Contender': <RocketOutlined />,
        'Prime Candidate': <FireOutlined />,
        'Top Prospect': <ThunderboltOutlined />,
        'Leader': <SmileOutlined />
    };

    const tierExplain = {
        'Explorer': "Tier 1: It looks like you are just getting started! Keep improving your profile to get noticed by employers!",
        'Seeker': "Tier 2: You appear to lack some key information in your profile. Keep going!",
        'Candidate': "Tier 3: You are close to the top 50% of candidates! Keep going!",
        'Contender': "Tier 4: You have a good chance of getting noticed by employers!",
        'Prime Candidate': 'Tier 5: Top 35% to 20% of candidates!',
        'Top Prospect': 'Tier 6: Top 20% of candidates!',
        'Leader': 'Tier 7: Top 10% of candidates!'
    };

    const tierAdjective = {
        'Explorer': 'Improving',
        'Seeker': 'Good',
        'Candidate': 'Great',
        'Contender': 'Excellent',
        'Prime Candidate': 'Outstanding',
        'Top Prospect': 'Exceptional',
        'Leader': 'Prime'
    };

    const getTier = (score) => {
        if (score <= 10) return 'Explorer';
        if (score <= 25) return 'Seeker';
        if (score <= 45) return 'Candidate';
        if (score <= 60) return 'Contender';
        if (score <= 75) return 'Prime Candidate';
        if (score <= 90) return 'Top Prospect';
        return 'Leader';
    };

    const getTierRanding = (score) => {
        if (score <= 10) return 0;
        if (score <= 25) return 1;
        if (score <= 45) return 2;
        if (score <= 60) return 3;
        if (score <= 75) return 4;
        if (score <= 90) return 5;
        return 6;
    };

    const getNextTier = (score) => {
        if (score <= 10) return 'Seeker';
        if (score <= 25) return 'Candidate';
        if (score <= 45) return 'Contender';
        if (score <= 60) return 'Prime Candidate';
        if (score <= 75) return 'Top Prospect';
        if (score <= 90) return 'Leader';
        return ''; // No next tier beyond Leader
    };

    const getNextTierThreshold = (score) => {
        if (score <= 10) return 25;
        if (score <= 25) return 45;
        if (score <= 45) return 60;
        if (score <= 60) return 75;
        if (score <= 75) return 90;
        return 100;
    };

    const dashboardProgress = (score) => {
        if (score <= 10) return 10;
        if (score <= 25) return 25;
        if (score <= 45) return 45;
        if (score <= 60) return 60;
        if (score <= 75) return 75;
        if (score <= 90) return 90;
        return 100;
    };

    const steps = [
        {
            icon: <TrophyOutlined />,
        },
        {

            icon: <StarOutlined />,
        },
        {
            icon: <CrownOutlined />,
        },
        {

            icon: <RocketOutlined />,
        },
        {
            icon: <FireOutlined />,
        },
        {
            icon: <ThunderboltOutlined />,
        },
        {
            icon: <SmileOutlined />,
        },
    ];

    //Evaluate Candidacy Function
    const evaluateCandidacy = async () => {

        setEvaluationLoading(true);

        try {
            let taskResponse = await startEvaluateTask(user._id);
            let taskId = taskResponse.data.taskID;

            let evaluationResultsPromise = backgroundJobCheck(getEvaluateTaskStatus, getEvaluateTaskResult, taskId);

            // Create a timeout promise that resolves after 2 seconds
            const timeoutPromise = new Promise(resolve => setTimeout(resolve, 2000));

            var result = await Promise.all([evaluationResultsPromise, timeoutPromise]);

            refetch();

            return result;
        } catch (error) {
            console.error("Error during evaluation:", error);
            // Handle the error appropriately, e.g., show a notification to the user
        } finally {
            setEvaluationLoading(false);

            if (result[0].result == "No Resume Data Found") {
                openNotificationWithIcon('error', 'No Resume Data Found', 'Please upload your resume to evaluate your candidacy.');
            }

            if (result[0].result == "Error") {
                openNotificationWithIcon('error', 'Evaluation Failed', 'An error occurred while evaluating your candidacy. Please try again later. If the problem persists, please contact support.');
            }
        }
    }

    if (!user) {
        return <div>Loading...</div>; // Or any other loading indicator
    }

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
                <Spin size="large" />
            </div>
        );
    }

    let scorePercent = 0;
    let nextTierThreshold = 0;
    let progressToNextTier = 0;
    let currentTier = 'Unknown';
    let dashboardscore = 0;


    if (evaluationReport) {
        scorePercent = Math.round((evaluationReport.yourScore / evaluationReport.maxScore) * 100);
        nextTierThreshold = getNextTierThreshold(scorePercent);
        progressToNextTier = Math.round((scorePercent / nextTierThreshold) * 100);
        currentTier = getTier(scorePercent);
        dashboardscore = dashboardProgress(scorePercent);
    }

    const formatTier = () => (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2px' }}>
            <Text style={{ fontSize: '26px', marginBottom: '-10px', marginTop: isMobile ? '0' : '-5px', padding: '0' }}>{Math.round(evaluationReport.yourScore)}</Text>
            <Text style={{ fontSize: '12px', margin: '0', padding: '0' }}>{tierAdjective[currentTier]}</Text>
            <Tooltip title={tierExplain[currentTier]}>
                <Space direction={currentTier == "Prime Candidate" ? "vertical" : "horizontal"} align="center" size={2}>
                    <span style={{ fontSize: '20px', margin: '0', padding: '0' }}>
                        {tierIcons[currentTier]}
                    </span>
                    <Text style={{ fontSize: '20px', marginTop: '0', padding: '0' }}>{currentTier}</Text>
                </Space>
            </Tooltip>
            <Text style={{ fontSize: '15px', fontWeight: 'bold', marginBottom: '10px', marginTop: '0px',  padding: '0' }}>Tier {getTierRanding(scorePercent) + 1}</Text>
        </div>
    );

     {/* <span style={{ fontSize: '12px' }}>
            {tierExplain[currentTier]}
        </span> */}

    const openNotificationWithIcon = (type, mes, description) => {
        const key = `open${Date.now()}`;

        const btn = (
            <Space>
                <Button className='button-color' type="primary" size="small" onClick={() => {
                    api.destroy(key);
                    navigate('/profile/resume');
                }}>
                    Go To Resume Upload
                </Button>
            </Space>
        );
        api[type]({
            message: mes,
            description: description,
            duration: 7,
            btn: mes === "No Resume Data Found" ? btn : null,
            key,

        });
    };

    return (
        <>
            <Space>{contextHolder}</Space>
            <Card bordered={false} style={{ alignItems: 'center', width: '100%' }} title="Evaluation Report">
                <Row style={{ width: '100%', flexDirection: isMobile ? 'column' : 'row' }}>
                    <Col span={isMobile ? 24 : 12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: isMobile ? '16px' : '0' }}>
                        {evaluationReport ? (
                            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ flex: '1 1 100%', maxWidth: '100%' }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Progress
                                            type="dashboard"
                                            percent={dashboardscore}
                                            strokeColor="#fcdc5c"
                                            trailColor="rgba(0, 0, 0, 0.06)"
                                            strokeWidth={12}
                                            steps={{
                                                count: 7,
                                                gap: 4,
                                            }}
                                            gapDegree={125}
                                            format={formatTier}
                                            style={{ marginBottom: '-40px' }}
                                            width={200}
                                        />

                                    </div>
                                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '200px', height: '200px', pointerEvents: 'none' }}>
                                        {/* <div style={{ position: 'absolute', top: '50%', left: '-10%', transform: 'translate(-50%, -50%) ', whiteSpace: 'nowrap' }}>Tier 1</div> */}
                                        {/* <div style={{ position: 'absolute', top: '20%', left: '-9%', transform: 'translate(-50%, -50%)', whiteSpace: 'nowrap' }}>Tier 2</div> */}
                                        {/* <div style={{ position: 'absolute', top: '-7%', left: '9%', transform: 'translate(-50%, -50%)', whiteSpace: 'nowrap' }}>Tier 3</div> */}
                                        {/* <div style={{ position: 'absolute', top: '-2%', left: '15%', transform: 'translate(-50%, -50%) rotate(45deg)', width: '10%', height: '1px', backgroundColor: 'black' }}></div> */}
                                        {/* <div style={{ position: 'absolute', top: '-15%', left: '48%', transform: 'translate(-50%, -50%)', whiteSpace: 'nowrap' }}>Tier 4</div> */}
                                        {/* <div style={{ position: 'absolute', top: '-7%', left: '89%', transform: 'translate(-50%, -50%)', whiteSpace: 'nowrap' }}>Tier 5</div> */}
                                        {/* <div style={{ position: 'absolute', top: '20%', left: '109%', transform: 'translate(-50%, -50%)', whiteSpace: 'nowrap' }}>Tier 6</div> */}
                                        {/* <div style={{ position: 'absolute', top: '50%', left: '110%', transform: 'translate(-50%, -50%)', whiteSpace: 'nowrap' }}>Tier 7</div> */}
                                    </div>
                                    {/* {!isMobile ? (
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Tooltip title="Your score is calculated based on the information you have provided in your profile.">
                                                    <InfoCircleTwoTone twoToneColor='#fcdc5c' style={{ fontSize: isMobile ? '14px' : '20px', marginRight: '5px' }} />
                                                </Tooltip>
                                                <Text>Progress to Next Tier: {getNextTier(scorePercent)}</Text>
                                            </div>
                                            <Progress type='line' percent={progressToNextTier} status="active" strokeColor="#fcdc5c" style={{ maxWidth: '300px' }} />
                                        </div>
                                    ) : null} */}
                                </div>

                                {/* <div style={{ flex: '1 1 100%', maxWidth: '100%', padding: '8px' }}>
                                    {isMobile ? (
                                        <>
                                            <Tooltip title="Your score is calculated based on the information you have provided in your profile.">
                                                <InfoCircleTwoTone twoToneColor='#fcdc5c' style={{ fontSize: isMobile ? '14px' : '20px', marginRight: '5px' }} />
                                            </Tooltip>
                                            <Text>Progress to Next Tier: {getNextTier(scorePercent)}</Text>
                                            <Progress type='line' percent={progressToNextTier} status="active" strokeColor="#fcdc5c" style={{ width: '100%' }} />
                                        </>
                                    ) : null}
                                </div> */}

                                <div style={{ flex: '1 1 100%', textAlign: 'center' }}>
                                    <Button loading={evaluationLoading} className="button-color-premium" type="primary" size='small' onClick={() => setEvaluationReportModalVisible(true)}>View Full Report</Button>
                                </div>
                            </div>
                        ) : (

                            evaluationLoading ? (
                                <Space direction="vertical" style={{ fontSize: '12px', textAlign: 'center' }}>
                                    <Spin size='large' />
                                    <Text>
                                        Evaluating your profile! This process may take some time. Please check back later to view your candidacy score.
                                    </Text>
                                </Space>

                            ) : (
                                <>
                                    <Button className="button-color-premium button-pulse" type="primary" size='small' onClick={evaluateCandidacy}>Evaluate Candidacy</Button>
                                </>
                            )

                        )}
                    </Col>
                    <Col span={isMobile ? 24 : 12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Descriptions
                            column={isMobile ? 1 : 1}
                            layout="horizontal"
                            bordered
                            style={{ textAlign: isMobile ? 'center' : 'left' }}
                        >
                            <Descriptions.Item label="Target Job">{user.targetJob?.title}</Descriptions.Item>
                            <Descriptions.Item label="Target Location">
                                {user.targetLocation?.city}, {user.targetLocation?.provinceOrState} {user.targetLocation?.countryCode}
                            </Descriptions.Item>
                            {user.targetJobLevel && (
                                <Descriptions.Item label="Target Job Level">{user.targetJobLevel}</Descriptions.Item>
                            )}
                        </Descriptions>
                    </Col>
                </Row>
                {/* <Steps items={steps} current={steps.findIndex(step => step.icon == tierIcons[currentTier])} /> */}
            </Card>
            <EvaluationReportModal reportData={evaluationReport} visible={evaluationReportModalVisible} tier={currentTier} dashboardscore={dashboardscore} formatTier={formatTier} lastUpdate={evaluationReport?.lastEvaluated} evaluateFunction={evaluateCandidacy} nextTierPrecentage={progressToNextTier} tierRanking={getTierRanding(scorePercent)} onClose={() => setEvaluationReportModalVisible(false)} />
        </>
    );

}

export default Target;



/* <Space direction="vertical" size="middle" style={{ textAlign: isMobile ? 'center' : 'left' }}>
    <Text><strong>Target Job:</strong> {user.targetJob?.title}</Text>
    <Text><strong>Target Location:</strong> {user.targetLocation?.city}, {user.targetLocation?.provinceOrState} {user.targetLocation?.countryCode}</Text>
    {user.targetJobLevel && (
        <Text><strong>Target Job Level:</strong> {user.targetJobLevel}</Text>
    )}
</Space> */