import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
//import { createUploadLink } from "apollo-upload-client";
//Stripe Libraries
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { Layout, Result, Button } from 'antd';
import { useLocation } from 'react-router-dom';
import { SmileOutlined } from '@ant-design/icons';

import Home from './pages/Home';
import FindJobs from './pages/FindJobs/FindJobs';
import NoMatch from './pages/NoMatch';
import Login from './pages/Login';
import Signup from './pages/Signup';
import WelcomeWizard from './pages/WelcomeWizard/WelcomeWizard';
import Nav from './components/Nav';
import Auth from './utils/auth'
import Applications from './pages/Applications/Applications';
import Contacts from './pages/Contacts/Contacts';
import PageNav from './components/PageNav';
import OptimizeResume from './pages/OptimizeResume/OptimizeResume';
import Profile from './pages/Profile/Profile';
import AccountDetails from './components/Profile/AccountDetails';
import EmailSettings from './components/Profile/EmailSettings';
import Resume from './components/Profile/Resume';
import CoverLetter from './components/Profile/CoverLetter';
import Billing from './components/Profile/Billing';
import OptimizeCL from './pages/OptimizeCL/OptimizeCL';
import EditResume from './pages/EditResume/EditResume';
import EditCoverLetter from './pages/EditCoverLetter/EditCoverLetter';
import HelpButton from './components/FloatButton/Help';
import Interview from './pages/Interview/Interview';
import Resumes from './pages/Resumes/Resumes';
import FeedbackThankYou from './pages/Feedback/Feedback';
import Matcher from './pages/Matcher/Matcher';
import Inbox from './pages/Inbox/Inbox'
import MobilePageNav from './components/MobilePageNav';
import MatcherEmails from './components/Profile/MatcherEmails';

import { FeatureFlagsContext } from './contexts/FeatureFlagsContext';
import { TourProvider } from './contexts/tourContext';
import { UserProvider } from './contexts/userContext';
import Feedback from './components/Home/Feedback';
import usePageTracking from './usePageTracking';

const stripePromise = loadStripe('pk_test_51O2KXfEIXZ5v9gRWAs6kRVY2fZFLcjFpVCR4J2QeuoP1ZCrtgDgbdkYiadpBMdz0oeEzyYowALLluWpW7qgpdAcT00JnvBX01H');
const { Content, Sider } = Layout;



const httpLink = createHttpLink({
  uri: '/graphql',
  // uri: 'https://project-hiremize-d575776c09e0.herokuapp.com/graphql',
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('id_token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const isWelcomeWizard = false;

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const useFeatureFlags = () => {
  const [featureFlags, setFeatureFlags] = useState(null);

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      const response = await axios.get('/setup/featureFlags');
      setFeatureFlags(response.data);
    };

    fetchFeatureFlags();
  }, []);

  return featureFlags;
}

function DisplaySideNav({ setIsSideNavDisplayed, setSidebarCollapsed }) {
  const location = useLocation();
  const hideOnRoutes = ['/profile', '/application/resume', '/application/coverletter', '/edit-resume', '/edit-coverletter', '/application/interview', '/feedback', '/signup', '/login', '/welcome'];
  const [collapsed, setCollapsed] = useState(false);

  const shouldHideNav = hideOnRoutes.some(route => location.pathname.startsWith(route));

  useEffect(() => {
    setIsSideNavDisplayed(Auth.loggedIn() && !shouldHideNav);
    setSidebarCollapsed(collapsed);
  }, [Auth.loggedIn(), shouldHideNav, collapsed]);

  function Navigation() {
    return (
      <>
        {Auth.loggedIn() && (
          <>
            <Nav />
          </>
        )}
      </>
    );
  }

  return (
    <>
      {Auth.loggedIn() && !shouldHideNav ? (
        <Sider style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
        }}
          width={250} collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
          <PageNav collapsedSidebar={collapsed} />
        </Sider>
      ) : (<Navigation />)}
    </>
  )
}

//-------------------Main App Component-------------------

function App() {
  const [isSideNavDisplayed, setIsSideNavDisplayed] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const featureFlags = useFeatureFlags();
  usePageTracking();

  if (featureFlags === null) {
    return <div>Loading...</div>; // or your custom loading component
  }

  // Determine if the app is being viewed on a mobile device
  const isMobile = window.innerWidth <= 768; //768px is the breakpoint for mobile devices

  // Component to handle unsupported mobile devices
  const UnsupportedDevice = () => (
    <Result
      icon={<SmileOutlined />}
      title="Sorry, this application is not supported on mobile devices at the moment. Please visit us on a laptop or desktop computer."
      extra={<Button className='button-color' onClick={() => window.location.href = 'https://www.hiremize.app'}>Back Home</Button>}
    />
  );

  return (
    <TourProvider>
      <FeatureFlagsContext.Provider value={featureFlags}>
        <ApolloProvider client={client}>
          <UserProvider>
            <Elements stripe={stripePromise}>
            {/* <Router> */}
            {/* {isMobile ? (
                <UnsupportedDevice />
              ) : ( */}
            <Layout
              style={{
                minHeight: '100vh',
                marginLeft: isSideNavDisplayed ? (sidebarCollapsed ? 50 : 250) : 0,
              }}
            >
              {featureFlags.pageNav.main && !isMobile && <DisplaySideNav setIsSideNavDisplayed={setIsSideNavDisplayed} setSidebarCollapsed={setSidebarCollapsed} />}

              {featureFlags.pageNav.main && isMobile && Auth.loggedIn() && <MobilePageNav />}

              <Content style={{ backgroundColor: 'white' }}>
                <div style={{ height: '100%' }}>
                  {/* <Navigation /> */}
                  <Routes>
                    {featureFlags.mainDashboard.main && (
                      <Route
                        path="/"
                        element={(Auth.loggedIn() ? (!isWelcomeWizard ? <Home /> : <Navigate to="/welcome" />) : <Login />)}
                      />
                    )}
                    {!featureFlags.mainDashboard.main && (
                      <Route
                        path="/"
                        element={<Navigate to="/applications" />}
                      />
                    )}
                    <Route path="/welcome" element={Auth.loggedIn() ? <WelcomeWizard /> : <Login />} />
                    <Route path="/login" element={!Auth.loggedIn() ? <Login /> : <Navigate to="/" />} />
                    <Route path="/signup" element={!Auth.loggedIn() ? <Signup /> : <Navigate to="/" />} />

                    {featureFlags.profileSettings.main && (
                      <Route
                        path="/profile"
                        element={(Auth.loggedIn() ? <Profile /> : <Login />)}
                      >
                        {featureFlags.profileSettings.account && (
                          <Route path="account" element={<AccountDetails />} />
                        )}
                        {featureFlags.profileSettings.email && (
                          <Route path="email" element={<EmailSettings />} />
                        )}
                        
                        <Route path="matcheremails" element={<MatcherEmails />} />
                        
                        {featureFlags.profileSettings.resume && (
                          <Route path="resume" element={<Resume />} />
                        )}
                        {featureFlags.profileSettings.coverLetter && (
                          <Route path="coverletter" element={<CoverLetter />} />
                        )}
                        {featureFlags.profileSettings.billing && (
                          <Route path="billing" element={<Billing />} />
                        )}
                      </Route>
                    )}

                    {/* {featureFlags.profileSettings.resume && (
                        <Route path="/resumebuilder" 
                        element={(Auth.loggedIn() ? <Resume /> : <Login />)} />
                      )} */}

                    <Route
                      path="/resumes"
                      element={(Auth.loggedIn() ? <Resumes /> : <Login />)}
                    />

                    {featureFlags.opportunities.main && ( //NO MOBILE SUPPORT
                      <Route
                        path="/find-jobs"
                        element={
                          Auth.loggedIn() ? (
                            isMobile ? (
                              <UnsupportedDevice />
                            ) : (
                              <FindJobs />
                            )
                          ) : (
                            <Login />
                          )
                        }
                      />
                    )}
                    {featureFlags.opportunities.matcher && (
                      <Route
                        path="/matcher"
                        element={(Auth.loggedIn() ? <Matcher /> : <Login />)}
                      />
                    )}
                    {featureFlags.applicationsTracker.main && ( //NO MOBILE SUPPORT
                      <Route
                        path="/applications"
                        element={
                          Auth.loggedIn() ? (
                            isMobile ? (
                              <UnsupportedDevice />
                            ) : (
                              <Applications />
                            )
                          ) : (
                            <Navigate to="/login" />
                          )
                        }
                      />
                    )}
                    {featureFlags.contactsTracker.main && ( //NO MOBILE SUPPORT
                      <Route
                        path="/contacts"
                        element={
                          Auth.loggedIn() ? (
                            isMobile ? (
                              // <UnsupportedDevice />
                              <Contacts />
                            ) : (
                              <Contacts />
                            )
                          ) : (
                            <Login />
                          )
                        }
                      />
                    )}
                    {featureFlags.contactsTracker.inbox && (
                      <Route
                        path="/inbox"
                        element={(Auth.loggedIn() ? <Inbox setSideBar={() => setSidebarCollapsed(false)} /> : <Login />)}
                      />
                    )}
                    {featureFlags.applicationsTracker.optimizeResume && ( //NO MOBILE SUPPORT
                      <Route
                        path="/application/resume/:JobId/:AppId"
                        element={
                          Auth.loggedIn() ? (
                            isMobile ? (
                              <UnsupportedDevice />
                            ) : (
                              <OptimizeResume />
                            )
                          ) : (
                            <Login />
                          )
                        }
                      />
                    )}
                    {featureFlags.applicationsTracker.optimizeCoverLetter && ( //NO MOBILE SUPPORT
                      <Route
                        path="/application/coverletter/:JobId/:AppId"
                        element={
                          Auth.loggedIn() ? (
                            isMobile ? (
                              <UnsupportedDevice />
                            ) : (
                              <OptimizeCL />
                            )
                          ) : (
                            <Login />
                          )
                        }
                      />
                    )}
                    {featureFlags.applicationsTracker.editResume && ( //NO MOBILE SUPPORT
                      <Route
                        path="/edit-resume/:id"
                        element={
                          Auth.loggedIn() ? (
                            isMobile ? (
                              <UnsupportedDevice />
                            ) : (
                              <EditResume />
                            )
                          ) : (
                            <Login />
                          )
                        }
                      />
                    )}
                    {featureFlags.applicationsTracker.editCoverLetter && ( //NO MOBILE SUPPORT
                      <Route
                        path="/edit-coverletter/:id"
                        element={
                          Auth.loggedIn() ? (
                            isMobile ? (
                              <UnsupportedDevice />
                            ) : (
                              <EditCoverLetter />
                            )
                          ) : (
                            <Login />
                          )
                        }
                      />
                    )}
                    <Route
                      path="/application/interview/:JobId/:AppId"
                      element={
                        Auth.loggedIn() ? (
                          isMobile ? (
                            <UnsupportedDevice />
                          ) : (
                            <Interview />
                          )
                        ) : (
                          <Login />
                        )
                      }
                    />
                    <Route
                      path="*"
                      element={<NoMatch />}
                    />
                    <Route
                      path="/feedback/:id/:response"
                      element={<FeedbackThankYou />}
                    />
                  </Routes>
                  {Auth.loggedIn() && <HelpButton />}
                </div>
              </Content>
            </Layout>
            {/* )} */}
            {/* </Router> */}
            </Elements>
          </UserProvider>
        </ApolloProvider>
      </FeatureFlagsContext.Provider>
    </TourProvider>
  );
}

export default App;
