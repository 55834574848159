import React, { useContext } from "react";
import { Card, Row, Col, Button } from "antd";
import useIsMobile from "../../contexts/useIsMobile";
import { useStripe } from "@stripe/react-stripe-js";
import { UserContext } from "../../contexts/userContext";

const PricingTable = ({sample}) => {

    const { user } = useContext(UserContext);

    const userSubscriptionStatus = user?.subscription?.status;
    const userSubscriptionTier = user?.subscription?.tier.toLowerCase();

    const stripe = useStripe();
    const isMobile = useIsMobile();

    const pricingData = [
        {
            title: "Basic",
            price: "Forever Free",
            features: [
                "Job Tracker",
                "Professional Relationship Manager",
                "Customized Application Guidance",
                "Customized Job Search Guidance",
                "Resume Builder & Manager"
            ],
            buttonText: "Choose Basic",
            tier: "Basic",
            priceId: "price_1QS4BkEIXZ5v9gRWR3ZQmphH",
        },
        {
            title: "Pro",
            price: <><del>$25/month</del> Free</>,
            features: [
                "Everything in Basic",
                "AI Resume & Cover Letter Generator",
                "Network Message Generator",
                "Basic Candidacy Evaluation",
                "25 Premium Opportunities/Week"
            ],
            buttonText: "Choose Pro",
            tier: "Pro",
            priceId: "price_1QS4CtEIXZ5v9gRWlrO9bDzS"
        },
        {
            title: "Premium",
            price: <><del>$50/month</del> Free</>,
            features: [
                "Everything in Standard",
                "One Click Auto-Apply",
                "Premium Insider Connections",
                "Advanced Candidacy Evaluation",
                "50 Premium Opportunities/Week"
            ],
            buttonText: "Choose Premium",
            tier: "Premium",
            priceId: "price_1QS4DxEIXZ5v9gRWHU1G3kro"
        },
    ];

    // Sort pricingData to have the active tier first if isMobile is true
    const sortedPricingData = isMobile
        ? [...pricingData].reverse()
        : pricingData;

    // Find the maximum number of features
    const maxFeatures = Math.max(...sortedPricingData.map(tier => tier.features.length));

    // Pad the features array with empty strings to match the length of the longest features array
    const paddedPricingData = sortedPricingData.map(tier => ({
        ...tier,
        features: [...tier.features, ...Array(maxFeatures - tier.features.length).fill("-")]
    }));

    // Calculate the column span based on the number of pricing tiers
    const colSpan = Math.floor(24 / pricingData.length);

    const ensureStripeCustomerId = async (email) => {
        const response = await fetch("/stripe/create-customer", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email }),
        });

        console.log(response);

        const { customerId } = await response.json();

        return customerId;
    };

    const handleCheckout = async (priceId) => {
        if (!priceId) {
            alert("You selected the free plan!");
            return;
        }

        var customerId = user.subscription.stripeCustomerId || "";
        if (!customerId) {
            customerId = await ensureStripeCustomerId(user.email);
        }


        try {
            const response = await fetch("/stripe/create-subscription", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    customerId,
                    priceId,
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error("Backend error:", errorData.error);
                return;
            }

            const { id: sessionId } = await response.json();

            // Redirect to Stripe Checkout
            const result = await stripe.redirectToCheckout({ sessionId });

            if (result.error) {
                console.error("Stripe Checkout Error:", result.error.message);
            }
        } catch (error) {
            console.error("Checkout error:", error);
        }
    };

    const handlePortal = async () => {
        try {
          // Ensure the user has a Stripe customer ID
          let customerId = user.subscription.stripeCustomerId || "";
          if (!customerId) {
            customerId = await ensureStripeCustomerId(user.email); // Implement this function if needed
          }
      
          // Call your backend to create a portal session
          const response = await fetch('/stripe/create-portal-session', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ customerId }),
          });
      
          if (!response.ok) {
            throw new Error('Failed to create portal session.');
          }
      
          const { url } = await response.json();
      
          // Redirect to the Stripe Customer Portal
          window.location.href = url;
        } catch (error) {
          console.error('Error redirecting to the portal:', error.message);
        }
      };


      var selectedIndex = isMobile ? 0 : 2;
      

    return (
        <div style={{ padding: "20px" }}>
            <Row gutter={[16, 16]} justify="center">
                {paddedPricingData.map((tier, index) => (
                    <Col xs={24} md={colSpan} key={index} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Card
                            title={tier.title}
                            bordered={false}
                            style={{
                                textAlign: "center",
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                border: index == selectedIndex && sample == true ? '2px solid #1890ff' : userSubscriptionStatus == "active" ? userSubscriptionTier == tier.title.toLowerCase() ? '2px solid #1890ff' : '1px solid #d9d9d9' : '1px solid #d9d9d9',
                                backgroundColor: userSubscriptionStatus == "active" ? userSubscriptionTier == tier.title.toLowerCase() ? 'white' : '#f5f5f5' : 'white',
                                color: userSubscriptionStatus == "active" ? userSubscriptionTier == tier.title.toLowerCase() ? 'inherit' : '#bfbfbf' : 'inherit',
                                opacity: userSubscriptionStatus == "active" ? userSubscriptionTier == tier.title.toLowerCase() ? 1 : 0.6 : 1,
                                pointerEvents: userSubscriptionStatus == "active" ? userSubscriptionTier == tier.title.toLowerCase() ? 'auto' : 'none' : 'auto',
                            }}
                            actions={!sample && ( [
                                <Button
                                    onClick={userSubscriptionStatus == "active" ? () => handlePortal() : () => handleCheckout(tier.priceId)}
                                    type="primary" className="button-color-premium" style={{ textAlign: 'center', marginRight: '0px' }}>{userSubscriptionStatus == "active" ? userSubscriptionTier == tier.title.toLowerCase() ? "Manage" : tier.buttonText : tier.buttonText}
                                </Button>
                            ])}
                        >
                            <div style={{ flex: 'none' }}>
                                <p style={{ fontSize: "24px", fontWeight: "bold", margin: 0 }}>{tier.price}</p>
                            </div>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                <ul style={{ listStyleType: "none", padding: 0, margin: 0, flex: 'none' }}>
                                    {tier.features.map((feature, idx) => (
                                        <li style={{ marginBottom: "10px" }} key={idx}>{feature}</li>
                                    ))}
                                </ul>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );;
};

export default PricingTable;