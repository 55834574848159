import React, { useState, useEffect, useContext } from 'react';
import './OptimizeCL.css';
import { Layout, Row, Col, Card, Input, Button, Spin, Typography, Space, Dropdown, Select } from 'antd';
import { notification } from 'antd';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
// Components
import JobPostingItem from '../../components/Cards/JobPostingItem';
import NoResumeDataModal from '../../components/Modals/NoResumeDataModal';
import CLPreviewer from '../../components/Previewers/CLPreviewer';
// Queries
import { QUERY_SINGLE_JOB_POSTING } from '../../utils/queries';
import { QUERY_ME } from '../../utils/queries';
import { QUERY_MY_RESUME_DATA } from '../../utils/queries';
import { QUERY_APPLICATIONS_COVERLETTER_ID } from '../../utils/queries';
// Mutations
import { ADD_CL_DATA } from '../../utils/mutations';
import { UPDATE_PARSE_JOB_DESCRIPTION } from '../../utils/mutations';
import { UPDATE_JOB_DESCRIPTION } from '../../utils/mutations';
import { UPDATE_APPLICATION_COVERLETTERID } from '../../utils/mutations';
// CL Builder API calls
import { fetchParseJD } from '../../utils/CreateResumeAPIs/parseJD';
import { fetchGenerateCLdata, startGenerateCLTask, getGenerateCLTaskStatus, getGenerateCLTaskResult } from '../../utils/CreateResumeAPIs/generateCL';
import { fetchCreateCL } from '../../utils/CreateResumeAPIs/createCL';

import { backgroundJobCheck } from '../../utils/helpers/background';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../contexts/userContext';


const { Sider, Content } = Layout;
const { TextArea } = Input;
const { Text } = Typography;


const OptimizeCL = () => {
  const { JobId, AppId } = useParams();
  const [loading, setLoading] = useState(false);
  const { data: dataAppCLID } = useQuery(QUERY_APPLICATIONS_COVERLETTER_ID, { variables: { id: AppId } });
  const { jobLoading, error, data } = useQuery(QUERY_SINGLE_JOB_POSTING, { variables: { id: JobId } });
  const { loading: loadingResumeDate, error: errorResumeDate, data: dataResumeData } = useQuery(QUERY_MY_RESUME_DATA);
  const { loading: loadingUser, error: errorUser, data: dataUser } = useQuery(QUERY_ME);
  const job = data?.singleJobPosting || {};
  const [addCLData, { error: errorAddCLData }] = useMutation(ADD_CL_DATA);
  const [updateParseJobDescription] = useMutation(UPDATE_PARSE_JOB_DESCRIPTION);
  const [updateApplicationCoverLetterID] = useMutation(UPDATE_APPLICATION_COVERLETTERID);
  const [updateJobDescription] = useMutation(UPDATE_JOB_DESCRIPTION);
  const [noResumeDataModalVisible, setNoResumeDataModalVisible] = useState(false);
  const [resumeFilePath, setResumeFilePath] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [resumeData, setResumeData] = useState('');
  const [htmlCreated, setHtmlCreated] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');
  const [theme, setTheme] = useState('MainTemplate');
  const [currentCLData, setCurrentCLData] = useState('');
  const [currentParseJD, setCurrentParseJD] = useState('');
  const [newCLID, setNewCLID] = useState('');
  const [currentCLID, setCurrentCLID] = useState('');
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  useEffect(() => {
    // Get the User's parsed resume data
    if (dataResumeData) {
      if (dataResumeData.user && dataResumeData.user.myResumeData) {
        setResumeData(dataResumeData.user.myResumeData.mainData);
      } else {
        setResumeData({})
        console.log("No Resume Data")
        setNoResumeDataModalVisible(true);
      }
    }
    // Get the Parsed Job Description if it already exists
    if (data && data.singleJobPosting && data.singleJobPosting.parsedDescription) {
      setCurrentParseJD(data.singleJobPosting.parsedDescription);
    }
  }, [dataResumeData, data]);

  useEffect(() => {
    if (data && data.singleJobPosting && data.singleJobPosting.description) {
      setJobDescription(data.singleJobPosting.description);
    }
  }, [data]);

  // Get the User's resume file path (this is needed for the resume suggestions API call)
  useEffect(() => {
    if (dataUser) {
      setResumeFilePath(dataUser.user.resume.url);
      if (dataUser.user.myCoverLetterData) {
        setNewCLID(dataUser.user.myCoverLetterData._id)
      }
    }
  }, [dataUser]);

  // Get the current CL ID
  useEffect(() => {
    if (dataAppCLID && dataAppCLID.getApplicationByID && dataAppCLID.getApplicationByID.coverletterID) {
      setCurrentCLID(dataAppCLID.getApplicationByID.coverletterID._id);
      setNewCLID(dataAppCLID.getApplicationByID.coverletterID._id);
    }
  }, [dataAppCLID]);

  if (jobLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const goBack = () => {
    navigate(-1);
    setTimeout(() => window.location.reload(), 250)
  };

  const openNotificationWithIcon = (type, mes, description) => {
    api[type]({
      message: mes,
      description: description,
    });
  };

  // ---------Functions for CL Creation---------

  const createCL = async () => {
    if (!jobDescription || jobDescription.trim() === '') {
      openNotificationWithIcon('error', 'Missing Job Description', 'Please enter a job description to continue.');
      return;
    }
    setLoading(true);
    var parsedJD = '';
    try {
      if (currentParseJD == "" || currentParseJD == null || currentParseJD == undefined) {
        const response = await fetchParseJD(jobDescription);
        parsedJD = response.data.parsedJobDescriptionData;
        //Turn parseJD into a string
        const stringifiedJD = JSON.stringify(parsedJD);
        //Upload the parsed JD to the database
        const { data } = await updateParseJobDescription({ variables: { id: job._id, parsedDescription: stringifiedJD } });
        console.log(data);
      } else {
        //Skip having to parse the JD if it already exists
        parsedJD = JSON.parse(currentParseJD);
      }
      const jdData = parsedJD;
      await updateJobDescription({ variables: { id: job._id, description: jobDescription } });

      // // Create the new resume data based on the parsed JD
      // const enhancedCLData = await fetchGenerateCLdata(resumeData, jdData);
      //Start the background job to create the Cover Letter data
      let response = await startGenerateCLTask(resumeData, jdData, user._id);
      let taskId = response.data.taskID;
      let responseStatus = await backgroundJobCheck(getGenerateCLTaskStatus, getGenerateCLTaskResult, taskId);
      let enhancedCLData = responseStatus.result;
      



      //Add the enhanced resume data to the database
      const { data } = await addCLData({
        variables: {
          userData: enhancedCLData.data.userData,
          currentDate: enhancedCLData.data.currentDate,
          jobData: enhancedCLData.data.jobData,
          coverLetterParagraphs: enhancedCLData.data.coverLetterParagraphs
        },
      });
      setNewCLID(data.addCoverLetterData._id);
      setCurrentCLData(enhancedCLData.data);
      // Update the application with the new CL ID
      await updateApplicationCoverLetterID({ variables: { id: AppId, coverletterId: data.addCoverLetterData._id, coverletterOptimized: true } });
      // Preview the resume
      const htmlData = await fetchCreateCL(enhancedCLData.data, theme, 'html');
      setHtmlContent(htmlData);
      setHtmlCreated(true);
      openNotificationWithIcon('success', 'Cover Letter Created', 'Your Cover Letter has been successfully created!');
    } catch (error) {
      if (error.message === 'Subscription limit exceeded') {
        openNotificationWithIcon('error', 'Subscription Limit Exceeded', 'You have exceeded your Cover Letter Enhancements limit. Please upgrade your subscription to continue.');
      } else {
      openNotificationWithIcon('error', 'Error Creating Cover Letter', 'An error occurred while creating your Cover Letter. Please try again.');
      console.error("Error creating resume:", error);
      }
    } finally {
      setLoading(false);
    }
  }


  return (
    <div>
      <Space>{contextHolder}</Space>
      <Link to="#" onClick={goBack} style={{ position: 'relative', top: 10, left: 10, fontSize: '0.7em' }}>&#8592; Back To Applications</Link>
      <Layout className='optimizeResumeMainContainer' style={{ background: '#fff' }}>
        <Sider width={800} style={{ background: '#fff', marginRight: '10px' }}>
          <Row gutter={8}>
            <Col span={12}>
              <JobPostingItem job={job} />
            </Col>
            <Col span={12}>
              <Card title="Cover Letter Enhancer">
                <Text>
                  Welcome to our Cover Letter Enhancement Service! To get started, kindly input the job description in the space provided. We offer tailored suggestions to elevate your Cover Letter. Ensure that your resume is uploaded to your profile for a seamless experience. Let's enhance your career prospects together!
                </Text>

                {/* <div style={{ width: '90%', marginTop: '5px', marginRight: 'auto', marginLeft: "auto", display: 'flex', justifyContent: 'center' }}>
                  {currentCLID && <Link to={`/edit-coverletter/${currentCLID}`}>Previously Created</Link>}
                </div> */}
                <Button type="primary" block loading={loading} className='button-color' style={{ marginTop: '15px' }} disabled={!newCLID}>
                  <Link to={`/edit-coverletter/${newCLID}`}>Edit Cover Letter</Link>
                </Button>
                <Button type="primary" block loading={loading} onClick={createCL} className='button-color-premium' style={{ marginTop: '15px' }}>
                  Create Cover Letter
                </Button>
              </Card>
            </Col>
          </Row>
          <Card title="Job Description" style={{ marginTop: '20px' }}>
            <TextArea
              rows={10}
              placeholder="Enter your Job Description"
              value={jobDescription && jobDescription.trim() !== '' ? jobDescription : ''}
              onChange={(e) => setJobDescription(e.target.value)}
            />
          </Card>
        </Sider>
        <Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {loading ? (
            <>
              <Spin size="large" />
              <div style={{ marginLeft: '5px' }}>Please give us a moment to create your cover letter!</div>
            </>
          ) : (
            <CLPreviewer intialHtmlData={htmlContent} currentCLData={currentCLData} clID={newCLID} />
          )
          }
        </Content>
      </Layout>

      {noResumeDataModalVisible && <NoResumeDataModal onClose={() => setNoResumeDataModalVisible(false)} visible={noResumeDataModalVisible} />}

    </div>

  );
};

export default OptimizeCL;
