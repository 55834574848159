import React, { useState, useEffect, useRef, useContext } from 'react';
import './OptimizeResume.css';
import { Layout, Row, Col, Card, Input, Button, Spin, Typography, Space, Dropdown, Select } from 'antd';
import { notification } from 'antd';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
// Components
import JobPostingItem from '../../components/Cards/JobPostingItem';
import NoResumeDataModal from '../../components/Modals/NoResumeDataModal';
import ResumePreviewer from '../../components/Previewers/ResumePreviewer';
import OptimizeResumeSuccess from '../../components/Modals/OptimizeResumeSuccess';
import CompareResumes from '../../components/Modals/CompareResumes';
// Queries
import { QUERY_SINGLE_JOB_POSTING } from '../../utils/queries';
import { QUERY_ME } from '../../utils/queries';
import { QUERY_MY_RESUME_DATA } from '../../utils/queries';
import { QUERY_APPLICATIONS_RESUME_ID } from '../../utils/queries';
// Mutations
import { ADD_RESUME_DATA } from '../../utils/mutations';
import { UPDATE_PARSE_JOB_DESCRIPTION } from '../../utils/mutations';
import { UPDATE_JOB_DESCRIPTION } from '../../utils/mutations';
import { UPDATE_APPLICATION_RESUMEID } from '../../utils/mutations';
// Resume Builder API calls
import { fetchResumeSuggestions } from './resumeSuggestions';
import { fetchParseJD, startParseJDTask, getParseJDTaskStatus, getParseJDTaskResult } from '../../utils/CreateResumeAPIs/parseJD';
import { fetchGenerateResume, startGenerateResumeTask, getGenerateResumeTaskStatus, getGenerateResumeTaskResult } from '../../utils/CreateResumeAPIs/generateResumeData';
import { fetchGenerateHTML } from '../../utils/CreateResumeAPIs/generateHTML';

import { backgroundJobCheck } from '../../utils/helpers/background';
import { useNavigate } from 'react-router-dom';
import { useTour } from '../../contexts/tourContext';
import { UserContext } from '../../contexts/userContext';

const close = () => {
  console.log(
    'Notification was closed. Either the close button was clicked or duration time elapsed.',
  );
};


const { Sider, Content } = Layout;
const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

const OptimizeResume = () => {
  const { JobId, AppId } = useParams();
  const [loading, setLoading] = useState(false);
  const { jobLoading, error, data } = useQuery(QUERY_SINGLE_JOB_POSTING, { variables: { id: JobId } });
  const { data: dataResumeData } = useQuery(QUERY_MY_RESUME_DATA);
  const { data: dataUser } = useQuery(QUERY_ME);
  const { data: dataAppResumeID, refetch } = useQuery(QUERY_APPLICATIONS_RESUME_ID, { variables: { id: AppId } });
  const job = data?.singleJobPosting || {};
  const [addResumeData, { error: errorAddResumeData }] = useMutation(ADD_RESUME_DATA);
  const [updateParseJobDescription] = useMutation(UPDATE_PARSE_JOB_DESCRIPTION);
  const [updateJobDescription] = useMutation(UPDATE_JOB_DESCRIPTION);
  const [updateApplicationResumeID] = useMutation(UPDATE_APPLICATION_RESUMEID);
  const [noResumeDataModalVisible, setNoResumeDataModalVisible] = useState(false);
  const [resumeFilePath, setResumeFilePath] = useState('');
  const [results, setResults] = useState("");
  const [jobDescription, setJobDescription] = useState('');
  const [resumeData, setResumeData] = useState('');
  const [htmlCreated, setHtmlCreated] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');
  const [theme, setTheme] = useState('even');
  const [currentResumeData, setCurrentResumeData] = useState('');
  const [currentParseJD, setCurrentParseJD] = useState('');
  const [newResumeID, setNewResumeID] = useState('');
  const [mySavedResumes, setMySavedResumes] = useState([]);
  const [enhancedResumeDataID, setEnhancedResumeDataID] = useState('');
  const [api, contextHolder] = notification.useNotification();
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [mainResumeHighlights, setMainResumeHighlights] = useState([]);
  const [enhancedResumeHighlights, setEnhancedResumeHighlights] = useState([]);
  const [showCompareResumeModal, setShowCompareResumeModal] = useState(false);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const { setTourSteps, startTour } = useTour();
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const tips = [
    "We are extracting all the vital information from the job description to enhance your resume.",
    "Hiremize offers a suite of features designed to help you land your dream job.",
    "Our resume enhancer is designed to help you stand out from the crowd.",
    "We are incorporating key words and phrases from the job description to optimize your resume.",
    "Upon completion, please review your enhanced resume to ensure it is accurate.",
    "Once your resume has been enhanced, you have the option to make additional edits or proceed to download a PDF version.",
  ];

  const [currentTip, setCurrentTip] = useState(tips[0]);


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTip(tips[(tips.indexOf(currentTip) + 1) % tips.length]);
    }, 5000);

    return () => clearInterval(interval); // This is important to clear the interval when the component unmounts
  }, [currentTip]);


  useEffect(() => {
    // Get the User's parsed resume data
    if (dataResumeData) {
      if (dataResumeData.user && dataResumeData.user.myResumeData) {
        setResumeData(dataResumeData.user.myResumeData.mainData);
        const highlights = dataResumeData.user.myResumeData.mainData.work.map(workItem => workItem.highlights);
        const flattenedHighlights = highlights.flat();
        setMainResumeHighlights(flattenedHighlights);
      } else {
        setResumeData({})
        console.log("No Resume Data")
        setNoResumeDataModalVisible(true);
      }
    }
    // Get the Parsed Job Description if it already exists
    if (data && data.singleJobPosting && data.singleJobPosting.parsedDescription) {
      setCurrentParseJD(data.singleJobPosting.parsedDescription);
    }
  }, [dataResumeData, data]);

  useEffect(() => {
    if (data && data.singleJobPosting && data.singleJobPosting.description) {
      setJobDescription(data.singleJobPosting.description);
    }
  }, [data]);

  console.log(job);

  // Get the User's resume file path (this is needed for the resume suggestions API call)
  // useEffect(() => {
  //   // if (dataUser && dataUser.user && dataUser.user.myResumeData) {
  //   //   setResumeFilePath(dataUser.user.resume.url);
  //   //   if (dataUser.user.savedResumes) {
  //   //     setMySavedResumes(dataUser.user.savedResumes);
  //   //   }
  //   //   //Add myResumeData to the array of saved resumes
  //   //   if (dataUser.user.myResumeData) {
  //   //     setMySavedResumes([...dataUser.user.savedResumes, { resumeName: 'Main Resume', resumeData: dataUser.user.myResumeData }]);
  //   //     // setMySavedResumes([{ resumeName: 'Main Resume', resumeData: dataUser.user.myResumeData }]);
  //   //     setNewResumeID(dataUser.user.myResumeData._id);
  //   //   } 
  //   // }
  // }, [dataUser]);

  useEffect(() => {
    if (dataUser && dataUser.user && dataUser.user.myResumeData) {
      if (!mySavedResumes.some(resume => resume.resumeName === 'Main Resume')) {
        setMySavedResumes(prevResumes => [
          // ...prevResumes,
          { resumeName: 'Main Resume', resumeData: dataUser.user.myResumeData }
        ]);
      }

      if (dataAppResumeID && dataAppResumeID.getApplicationByID && dataAppResumeID.getApplicationByID.resumeID) {
        console.log("setting enhanced resume data")
        if (!mySavedResumes.some(resume => resume.resumeName === 'Enhanced Resume')) {
          setMySavedResumes(prevResumes => [
            ...prevResumes,
            { resumeName: 'Enhanced Resume', resumeData: dataAppResumeID.getApplicationByID.resumeID }
          ]);
        }
        setNewResumeID(dataAppResumeID.getApplicationByID.resumeID._id);
        setEnhancedResumeDataID(dataAppResumeID.getApplicationByID.resumeID._id);
      } else {
        setNewResumeID(dataUser.user.myResumeData._id);
      }
    }
  }, [dataUser, dataAppResumeID, currentResumeData, refetch]);

  useEffect(() => {
    console.log('setting steps');
    // Define the tour steps
    setTourSteps([
      {
        target: () => ref1.current,
        title: 'Copy and Paste Job Description',
        description: 'Copy and paste the job description in this text area.',
      },
      {
        target: () => ref2.current,
        title: 'Enhance Resume',
        description: 'Click this button to enhance your resume based on the job description',
      }
    ]);
    //startTour();

    const isFirstTime = localStorage.getItem('isFirstTime');
    console.log('isFirstTime:', isFirstTime);

    if (!isFirstTime) {
      console.log('Starting tour...');
      startTour();
      localStorage.setItem('isFirstTime', 'false');
    }

  }, [setTourSteps]);

  // useEffect(() => {
  //   const isFirstTime = localStorage.getItem('isFirstTime');
  //   console.log('isFirstTime:', isFirstTime);

  //   if (!isFirstTime) {
  //       console.log('Starting tour...');
  //       startTour();
  //       localStorage.setItem('isFirstTime', 'false');
  //   }
  // }, []);

  if (jobLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const goBack = () => {
    navigate(-1);
    setTimeout(() => window.location.reload(), 250)
  };

  const openNotificationWithIcon = (type, mes, description) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button type="primary" size="small" onClick={() => api.destroy(key)}>
          Got It!
        </Button>
      </Space>
    );
    api[type]({
      message: mes,
      description: description,
      duration: 0,
      btn,
      key,
      onClose: close,
    });
  };

  // -----------Resume Suggestions API call-----------
  const fetchMessage = async () => {
    console.log(resumeFilePath);
    console.log(jobDescription);
    const suggestions = await fetchResumeSuggestions(resumeFilePath, jobDescription);
    console.log(suggestions);
    setResults(suggestions);
  };
  const handleOk = async () => {
    setLoading(true);
    try {
      await fetchMessage();
    } catch (error) {
      console.error("Error fetching the follow-up message:", error);
    } finally {
      setHtmlCreated(false);
      setLoading(false);
    }
  };

  // ---------Functions for Resume Creation---------
  function cleanData(data) {
    if (Array.isArray(data)) {
      return data.map(cleanData);
    } else if (data !== null && typeof data === 'object') {
      return Object.entries(data).reduce((newObj, [key, value]) => {
        if (key !== '__typename') {
          newObj[key] = cleanData(value);
        }
        return newObj;
      }, {});
    } else {
      return data;
    }
  }

  const createResume = async () => {
    if (!jobDescription || jobDescription.trim() === '') {
      openNotificationWithIcon('error', 'Missing Job Description', 'Please enter a job description to continue.');
      return;
    }
    setLoading(true);
    var parsedJD = '';
    try {
      if (currentParseJD == "" || currentParseJD == null || currentParseJD == undefined) {
        // const response = await fetchParseJD(jobDescription);
        // parsedJD = response.data.parsedJobDescriptionData;
        //Start Parse JD Task
        let taskResponse = await startParseJDTask(jobDescription);
        let taskId = taskResponse.data.taskID;
        let response = await backgroundJobCheck(getParseJDTaskStatus, getParseJDTaskResult, taskId);
        parsedJD = response.result;
        console.log(parsedJD);
        //Turn parseJD into a string
        const stringifiedJD = JSON.stringify(parsedJD);
        //Upload the parsed JD to the database
        const { data } = await updateParseJobDescription({ variables: { id: job._id, parsedDescription: stringifiedJD } });
        console.log(data);
      } else {
        //Skip having to parse the JD if it already exists
        parsedJD = JSON.parse(currentParseJD);
      }
      // Add Job Description to the database
      await updateJobDescription({ variables: { id: job._id, description: jobDescription } });

      //Start Resume Generation Task
      let taskResponse = await startGenerateResumeTask(resumeData, parsedJD, user._id);
      let taskId = taskResponse.data.taskID;
      let response = await backgroundJobCheck(getGenerateResumeTaskStatus, getGenerateResumeTaskResult, taskId);
      let enhancedResume = response.result;


      const cleanedResumeData = cleanData(enhancedResume);

      //Add the enhanced resume data to the database
      const { data } = await addResumeData({
        variables: {
          mainData: cleanedResumeData,
          dataType: 'enhanced',
          savedName: job.company + ' - ' + job.position,
        },
      });
      setNewResumeID(data.addResumeData._id);
      setCurrentResumeData(cleanedResumeData);
      // Update the application with the new resume ID
      //console.log(AppId);

      // setMySavedResumes(prevResumes => [
      //   ...prevResumes,
      //   { resumeName: 'Enhanced Resume', resumeData: data.addResumeData._id }
      // ]);
      // setEnhancedResumeDataID(data.addResumeData._id);
      await updateApplicationResumeID({ variables: { id: AppId, resumeId: data.addResumeData._id, resumeOptimized: true } });
      refetch();
      // Preview the resume
      const htmlData = await fetchGenerateHTML(cleanedResumeData, theme);
      setHtmlContent(htmlData);
      setHtmlCreated(true);
      //Get the highlights of the enhanced resume
      const highlights = cleanedResumeData.work.map(workItem => workItem.highlights);
      const flattenedHighlights = highlights.flat();
      setEnhancedResumeHighlights(flattenedHighlights);
      openNotificationWithIcon('success', 'Resume Enhanced!', (
        <>
          Your resume has been successfully enhanced! Click <strong>Download PDF</strong> to get a copy, or <strong>Edit Resume</strong> to make further changes. Head back to Application to view your <strong>Next Steps such as creating a Cover Letter</strong>.
        </>
      ))
      setSuccessModalVisible(true);
    } catch (error) {
      if (error.message === 'Subscription limit exceeded') {
        openNotificationWithIcon('error', 'Subscription Limit Exceeded', 'Please upgrade your subscription to enhance your resume.');
      } else {
        console.error("Error creating resume:", error);
        openNotificationWithIcon('error', 'Error Enhancing Resume', 'Please try again, if the problem persists contact support')
      }
    } finally {
      setLoading(false);
    }
  }


  return (
    <div>
      <Space>{contextHolder}</Space>
      <Link to="#" onClick={goBack} style={{ position: 'relative', top: 10, left: 10, fontSize: '0.7em' }}>&#8592; Back To Applications</Link>
      <Layout className='optimizeResumeMainContainer' style={{ background: '#fff' }}>
        <Sider width={800} style={{ background: '#fff', marginRight: '10px' }}>
          <Row gutter={8}>
            <Col span={12}>
              <JobPostingItem job={job} />
            </Col>
            <Col span={12}>
              <Card title="Resume Enhancer">
                <Text>
                  Welcome to our Resume Enhancement Service! To get started, kindly input the job description in the space provided. We offer tailored suggestions to elevate your resume. Ensure that your resume is uploaded to your profile for a seamless experience. Let's enhance your career prospects together!
                </Text>

                <div style={{ width: '90%' }}>
                  {enhancedResumeDataID && (
                    <Row gutter={24} justify="center">
                      <Col span={16}>
                        <Select defaultValue="Enhanced Resume" style={{ width: '100%', marginTop: '15px' }} placeholder="Select a resume" onChange={value => { setNewResumeID(value); setHtmlCreated(false) }}>
                          {mySavedResumes.map(resume => (
                            <Option key={resume.resumeData._id} value={resume.resumeData._id}>{resume.resumeName}</Option>
                          ))}
                        </Select>
                      </Col>
                      {/* <Col span={8} style={{padding:'0px'}}>
                      <Button type="primary" onClick={() => setShowCompareResumeModal(true)} block loading={loading} className='button-color-premium' style={{ marginTop: '15px' }} >Compare</Button>
                      </Col> */}
                    </Row>
                  )}
                  {/* {enhancedResumeDataID && <Link to={`/edit-resume/${enhancedResumeDataID}`}>Previously Created</Link>} */}
                </div>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Space style={{ marginTop: '5px' }}>
                    {/* <Button type="primary" block loading={loading} onClick={handleOk} >
                      Get Resume Suggestions
                    </Button> */}
                  </Space>
                </div>
                {/* <Button type="primary" block loading={loading} onClick={() => setSuccessModalVisible(true)} className='button-color' style={{ marginTop: '15px' }}>Temp Enhance Resume</Button> */}
                <Button type="primary" block loading={loading} className='button-color' style={{ marginTop: '15px' }} >
                  <Link to={`/edit-resume/${newResumeID}`}>Edit Resume</Link>
                </Button>
                <Button ref={ref2} type="primary" block loading={loading} onClick={createResume} className='button-color-premium' style={{ marginTop: '15px' }}>
                  Enhance Resume
                </Button>
              </Card>
            </Col>
          </Row>
          <Card ref={ref1} title="Job Description" style={{ marginTop: '20px' }}>
            <TextArea
              rows={10}
              placeholder="Enter your Job Description"
              value={jobDescription && jobDescription.trim() !== '' ? jobDescription : ''}
              onChange={(e) => setJobDescription(e.target.value)}
            />
          </Card>
        </Sider>
        <Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {loading ? (
            <>
              <Card style={{ marginTop: '20px', marginLeft: '40px', marginRight: '40px', padding: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                  <Spin tip="Reviewing" size="large" />
                  <Typography.Paragraph style={{ marginLeft: '20px', fontSize: '18px', color: '#333' }}>
                    {currentTip}
                  </Typography.Paragraph>
                </div>
                <Typography.Title level={4} style={{ marginTop: '20px' }}>
                  Enhancing Your Resume
                </Typography.Title>
                <Typography.Paragraph>
                  Please give us a moment as we optimize your resume! This may take a few minutes.
                </Typography.Paragraph>
              </Card>
              {/* <div style={{ marginLeft: '5px' }}>Please give us a moment as we optimize your resume! This may take a few minutes.</div> */}
            </>

          ) : htmlCreated ? (
            <ResumePreviewer intialHtmlData={htmlContent} currentResumeData={currentResumeData} appID={newResumeID} />
          ) : (
            <ResumePreviewer ResumeID={newResumeID} />
          )}
        </Content>
      </Layout>

      {noResumeDataModalVisible && <NoResumeDataModal onClose={() => setNoResumeDataModalVisible(false)} visible={noResumeDataModalVisible} />}
      {successModalVisible && <OptimizeResumeSuccess onClose={() => setSuccessModalVisible(false)} visible={successModalVisible} beforeText={mainResumeHighlights} afterText={enhancedResumeHighlights} />}
      {showCompareResumeModal && <CompareResumes onClose={() => setShowCompareResumeModal(false)} visible={showCompareResumeModal} MainResume={mySavedResumes[0]} EnhancedResume={mySavedResumes[1]} />}

    </div>

  );
};

export default OptimizeResume;
