import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Button, Typography, Card, Space, Divider, Collapse, Alert } from 'antd';
import BaseResumeAnalysisComponent from '../../../BaseResumeAnalysis/Analysis/index';
import { Link } from 'react-router-dom';
import EditResumeModal from './EditResumeModal';
import { UserContext } from '../../../../../contexts/userContext';

import LoadingMatches from '../../../../Loading/LoadingMatches/index'

const { Title, Text } = Typography;
const { Panel } = Collapse;

const Review = ({ userData, setStep, close, loadingMatches }) => {

    const { user, myResumeData, refetchMyResume } = useContext(UserContext);

    const resumeID = user.myResumeData ? user.myResumeData._id : null;

    const [editResume, setEditResume] = useState(false);

    const [progress, setProgress] = useState(0);
    const [showProgress, setShowProgress] = useState(true);

    const hasLinkedInProfile = myResumeData && myResumeData.basics && myResumeData.basics.profiles.some(profile => profile.url.includes('linkedin'));
    const nonEmptyFields = Object.values(user.matcherSignature).filter(value => value !== null && value !== undefined && value !== '');
    const userSignatureNeedsWork = nonEmptyFields.length < 4;


    useEffect(() => {
        refetchMyResume();
    }, [editResume]);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 100) {
                    clearInterval(interval);
                    return 100;
                }
                return prevProgress + 1;
            });
        }, 500); // Adjust the interval time as needed

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (!loadingMatches) {
            const interval = setInterval(() => {
                setProgress((prevProgress) => {
                    if (prevProgress >= 100) {
                        clearInterval(interval);
                        setTimeout(() => setShowProgress(false), 2000); // Stay at 100% for 2 seconds
                        return 100;
                    }
                    return prevProgress + 10; // Speed up the progress
                });
            }, 50); // Faster interval time to quickly reach 100%

            return () => clearInterval(interval);
        }
    }, [loadingMatches]);

    if (showProgress) {
        return (
            <LoadingMatches progress={progress} />
        );
    }


    return (
        editResume ?
            <EditResumeModal visible={editResume} resumeID={resumeID} onClose={setEditResume} /> :

            <Row gutter={16}>


                {/* <Col span={11}>
                    <Space
                        direction="vertical"
                        style={{
                            width: '100%',
                        }}
                    >
                        {myResumeData ? (
                            <>
                                <Title level={4}>Resume Analysis</Title>
                                <div style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                                    <BaseResumeAnalysisComponent resumeID={resumeID} resume={myResumeData} />
                                    <Row align="middle">
                                        <Col span={6}>
                                            <Button size='small' className='button-color' type="primary" onClick={() => setEditResume(true)}>
                                                Edit Resume
                                            </Button>
                                        </Col>
                                        <Col span={18}>
                                            <Text style={{ fontSize: '12px' }}>Add or update any resume information, we recommend completing your resume maximize all features of hiremize</Text>
                                        </Col>
                                    </Row>

                                </div>
                            </>
                        ) : (
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%', alignItems: 'center', marginTop: '10%' }}>
                                <Title level={4}>Upload Your Resume</Title>
                                <Text>
                                    To unlock the full potential of our platform, please upload your resume. This will allow us to tailor the experience to your unique skills and background.
                                </Text>
                                <ul style={{ textAlign: 'left', marginTop: '20px' }}>
                                    <li>- Personalized email messages</li>
                                    <li>- AI-generated resumes and cover letters</li>
                                    <li>- Resume review and job strategy</li>
                                    <li>- Better job matches</li>
                                    <li>- And more</li>
                                </ul>
                            </div>
                        )}

                        <Title level={4}>Email Settings</Title>
                        <div style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>

                            <Collapse >
                                <Panel
                                    header={hasLinkedInProfile ? ("LinkedIn Profile Found") : (<Alert description="LinkedIn Profile Not Found" type="info" showIcon style={{ padding: '5px' }} />)}
                                    key="1">
                                    {!hasLinkedInProfile ? (
                                        <Row align="middle">
                                            <Col span={6}>
                                                <Button size='small' className='button-color' type="primary" onClick={() => setEditResume(true)}>
                                                    Add LinkedIn Profile
                                                </Button>
                                            </Col>
                                            <Col span={18}>
                                                <Text style={{ fontSize: '12px' }}>Add your LinkedIn profile for more job opportunities</Text>
                                            </Col>
                                        </Row>
                                    ) : (
                                        <Row align="middle">
                                            We found your LinkedIn profile.
                                        </Row>
                                    )}
                                </Panel>
                                <Panel header="Email Template" key="2">
                                    <Row align="middle">
                                        <Col span={6}>
                                            <Button size='small' className='button-color' type="primary" onClick={() => setEditResume(true)}>
                                                Edit Email
                                            </Button>
                                        </Col>
                                        <Col span={18}>
                                            <Text style={{ fontSize: '12px' }}>Create a custom email template to improve your job matches</Text>
                                        </Col>
                                    </Row>
                                </Panel>
                                <Panel
                                    header={userSignatureNeedsWork ? (<Alert description="Improve Your Email Signature" type="info" showIcon style={{ padding: '5px' }} />) : ("Email Signature Looks Good")}
                                    key="3">
                                    {userSignatureNeedsWork ? (
                                        <Row align="middle">
                                            <Col span={6}>
                                                <Button size='small' className='button-color' type="primary" onClick={() => setEditResume(true)}>
                                                    Edit Signature
                                                </Button>
                                            </Col>
                                            <Col span={18}>
                                                <Text style={{ fontSize: '12px' }}>Edit your email signature. We recommend including at least your name, job title or education, LinkedIn profile, and email address.</Text>
                                            </Col>
                                        </Row>
                                    ) : (
                                        <Row align="middle">
                                            Your email signature looks good.
                                        </Row>
                                    )}
                                </Panel>
                            </Collapse>
                        </div>

                        <Title level={4}>Candidacy Ranking</Title>
                        <Text>View your candidacy score to see how you rank against other candidates</Text>
                        <Button size='small' className='button-color-premium' type="primary" onClick={() => setStep(3)}>
                            Evaluate Candidacy
                        </Button>

                    </Space>
                </Col>

                <Col span={1}>
                    <div style={{ borderLeft: '1px solid #ddd', height: '100%' }} />
                </Col> */}

                <Col span={31} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Card bordered={false} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <Space direction="vertical" size="middle" style={{ textAlign: 'center' }} >
                            <Title level={4}>Job Details</Title>
                            <Text>Here is a summary of the information you provided:</Text>
                            <Text><strong>Target Job:</strong> {userData.targetJob.title}</Text>
                            <Text><strong>Target Location:</strong> {userData.targetLocation.city}, {userData.targetLocation.provinceOrState} {userData.targetLocation.countryCode}</Text>
                            {userData.yearsExperience && (
                                <Text><strong>Years of Experience:</strong> {userData.yearsExperience}</Text>
                            )}
                            {userData.targetJobLevel && (
                                <Text><strong>Target Job Level:</strong> {userData.targetJobLevel}</Text>
                            )}
                        </Space>
                    </Card>

                    <Space direction="vertical" size="middle" style={{ marginTop: '5%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Row align="middle">

                            <Text>Connect with job opportunities in one click. View leads we found to be a great fit for you.</Text>
                        </Row>
                        <Row align="middle" style={{ marginTop: '15px' }}>
                            <Button size='large' className='button-color-premium view-matches-button' type="primary" onClick={close}>
                                <Link to={`/matcher`}>View Matches</Link>
                            </Button>
                        </Row>
                        {/* <Row align="middle">
                            <Col span={6}>
                                <Button className='button-color' type="primary">Get Feedback</Button>
                            </Col>
                            <Col span={18}>
                                <Text>Receive personalize feedback, job strategy tips, recommendations to secure your target job</Text>
                            </Col>
                        </Row> */}
                    </Space>

                </Col>
            </Row>
    );
}

export default Review;


